import "./scss/App.scss";
import LogIn from "./pages/Doctor/Login";
import { ThemeProvider } from "@material-ui/core/styles";
import { Route, Switch } from "react-router-dom";
import { theme } from "./utility/themeSettings";
import NotFound from "./pages/not-found/NotFound";
import DetailsPage from "./pages/Doctor/DetailsPage";
import JoinPage from "./pages/Doctor/JoinPage";
import ErrorBoundary from "./pages/error-boundary/ErrorBoundary";
import GlobalContext from "./context/GlobalContext";
import PatientRecords from "./components/PatientRecords";
import { useEffect } from "react";
import VerifyBot from "./components/VerifyBot";
import HospitalErrorPage from "./pages/Doctor/HospitalErrorPage";
import Appointment from "./pages/Doctor/Appointment";
import SnackbarAlert from "./components/SnackbarAlert";
import { PatientpageContext } from "./context/GlobalContext";
import AddServices from "./components/AddServices";
import Prescription from "./pages/Doctor/Prescription";
import OverlayManager from "./OverlayManager/OverlayManager";
import Overlay from "./OverlayManager/Overlay";
import Order from "./components/Order";
import PatientNotes from "./pages/Doctor/PatientNotes";
// import PrescriptionNotes from "./pages/Doctor/PrescriptionNotes";
import Doctorregis from "./pages/Doctor/Doctoregis";
// import Homepage from "./pages/Doctor/Homepage";
// import Blog from "./pages/Doctor/Blog";
// import Doctordetails from "./pages/Doctor/Doctordetails";
import Doctorbutton from "./pages/Doctor/Doctorbutton";
import PlanSubcrip from "./pages/Doctor/PlanSubcrip";
import BasicDetails from "./components/BasicDetails";
import AwardNRew from "./components/AwardsNRew";
import EducaNexp from "./components/EducaNexp";
import Sociallink from "./components/Sociallink";
import Facility from "./components/facility";
import Offerservice from "./components/Addservice";
import DoctorMyScheduleTab from "./pages/Doctor/DoctorMyScheduleTab";
import NewPrescriptionTab from "./components/NewPrescriptionTab";
import AppointmentTab from "./components/AppointmentTab";
import Addaccount from "./components/Addaccount";
import Verifyac from "./components/Verifyac";
import Checkout from "./components/Checkout";
import Mypatient from "./pages/Doctor/Mypatient";
import Sidenav from "./components/Sidenav";
import Doctortrends from "./pages/Doctor/Doctortrends";
import Patientexperience from "./pages/Doctor/Patientexperience";
import PatientDetails from "./pages/Doctor/PatientDetails";
import Customtemp from "./pages/Doctor/Customtemp";
import Mymessage from "./pages/Doctor/Mymessage";
import Doctornote from "./pages/Doctor/Doctornote";
import Notes from "./pages/Doctor/Notes";
import Medicalrecords from "./pages/Doctor/Medicalrecords";
import Vital from "./pages/Doctor/Vital";
import Vital1 from "./pages/Doctor/Vital1";
import Notification from "./components/Notification";
import QuadrantGrid from "./components/QuadrantGrid";
import AddFile from "./components/AddFile";
import Partialpayement from "./components/Partialpayement";
import Referals from "./pages/Doctor/Referals";

function App() {


  return (
    <div className="wrapper-background">
      <div className="overflow-hidden app-screen">
        <ErrorBoundary>
          <ThemeProvider theme={theme}>
            <GlobalContext>
              <Overlay>
                <Switch>
                  <Route
                    exact
                    path={`${process.env.PUBLIC_URL}/`}
                    component={LogIn}
                  />
                  <Route
                    exact
                    path={`${process.env.PUBLIC_URL}/signup`}
                    component={() => <Doctorregis />}
                  />

                  <Sidenav>
                    <Switch>
                      <Route
                        exact
                        path="/home"
                        component={() => <DetailsPage pageTitle="EMR" />}
                      />
                      {/* <Route
                        path={`${process.env.PUBLIC_URL}/homie`}
                        component={Homepage}
                      /> */}
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL}/my-appointments`}
                        component={() => <Appointment />}
                      />

                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL}/write-prescriptions`}
                        component={() => <Prescription />}
                      />
                          <Route
                        exact
                        path={`${process.env.PUBLIC_URL}/dental-prescriptions`}
                        component={() => <QuadrantGrid />}
                      />

<Route
                        exact
                        path={`${process.env.PUBLIC_URL}/add-files`}
                        component={() => <AddFile />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL}/add-services`}
                        component={() => <AddServices />}
                      />
                      {/* <Route path="/doctor-detail" component={Doctordetails} /> */}
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL}/my-profile`}
                        component={() => <Doctorbutton />}
                      />

                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL}/basic-btn`}
                        component={() => <BasicDetails />}
                      />

                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL}/AwardNprice`}
                        component={() => <AwardNRew />}
                      />

                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL}/EducationNexp`}
                        component={() => <EducaNexp />}
                      />

                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL}/Sociallink`}
                        component={() => <Sociallink />}
                      />

                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL}/Facility`}
                        component={() => <Facility />}
                      />

                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL}/Addservice`}
                        component={() => <Offerservice />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL}/my-schedule`}
                        component={() => <DoctorMyScheduleTab />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL}/my-prescriptions`}
                        component={() => <NewPrescriptionTab />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL}/Accountdetail`}
                        component={() => <Addaccount />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL}/Verifyaccount`}
                        component={() => <Verifyac />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL}/Checkout/:plan_name`}
                        component={() => <Checkout />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL}/trends`}
                        component={() => <Doctortrends />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL}/patient-experience`}
                        component={() => <Patientexperience />}
                      />

                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL}/plans`}
                        component={PlanSubcrip}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL}/my-patients`}
                        component={() => <Mypatient />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL}/patient-details`}
                        component={() => <PatientDetails />}
                      />

                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL}/custom-templates`}
                        component={() => <Customtemp />}
                      />

                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL}/my-messages`}
                        component={() => <Mymessage />}
                      />

                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL}/Doctor-notes`}
                        component={() => <Doctornote />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL}/my-notes`}
                        component={() => <Notes />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL}/medical-records`}
                        component={() => <Medicalrecords />}
                      />
                         <Route
                        exact
                        path={`${process.env.PUBLIC_URL}/vitals`}
                        component={() => <Vital />}
                      />

                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL}/vital`}
                        component={() => <Vital1 />}
                      />
                      
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL}/referrals`}
                        component={() => <Referals />}
                      />
                      
                      {/* <Route
                    exact
                    path={`${process.env.PUBLIC_URL}/prescription/:id`}
                    component={() => <PrescriptionNotes />}
                  /> */}
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL}/add-services/order/:mode`}
                        component={() => <Order />}
                      />
                           <Route
                        exact
                        path={`${process.env.PUBLIC_URL}/order/payement`}
                        component={() => <Partialpayement />}
                      />

                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL}/prescription/patient_notes`}
                        component={() => <PatientNotes />}
                      />

                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL}/records`}
                        component={() => <PatientRecords />}
                      />
                       <Route
                        exact
                        path={`${process.env.PUBLIC_URL}/notification`}
                        component={() => <Notification />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL}/home/:tabIndex`}
                        component={() => (
                          <DetailsPage pageTitle="Doctor Page" />
                        )}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL}/join/:roomid`}
                        component={() => <JoinPage pageTitle="JoinPage" />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL}/hospitalError`}
                        component={() => <HospitalErrorPage />}
                      />
                      {/* <Route path="/blogs" element={<Blog />} /> */}

                      <Route component={NotFound} />
                    </Switch>
                  </Sidenav>
                </Switch>
                <OverlayManager />
                <SnackbarAlert />
                <VerifyBot />
              </Overlay>
            </GlobalContext>
          </ThemeProvider>
          
        </ErrorBoundary>
      </div>
    </div>
  );
}

export default App;