import React, { useContext, useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import PersonIcon from "@mui/icons-material/Person";
import GroupsIcon from "@mui/icons-material/Groups";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { PatientpageContext } from "../context/GlobalContext";
import {
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  IconButton,
  Box,
  Typography,
  Snackbar,
  Button,
  Alert,
  CircularProgress,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { countriesList } from "../utility/constants";
import { ServiceCall } from "../utility/service-call";
import { Ls } from "../utility/utility";
import { CloudUpload } from "@mui/icons-material";
import Overlay from "./Overlay";
function ProfileCard1(props) {
  const {
    name,
    gender,
    age,
    email,
    profileType,
    oneditClick,
    profile_id,
    phone,
    phoneCode,
    setShowProfilesModal,
    fetchReferralList,
  } = props;

  const doctorContext = useContext(PatientpageContext);
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [appointmentLoader, setAppointmentLoader] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // Initial state is loading
  const setState = doctorContext.setState;

  // State for editable fields with initial empty values
  const [editableFields, setEditableFields] = useState({
    preferredCountry: "",
    preferredCity: "",
    preferredHospital: "",
    preferredDoctor: "",
    passportNumber: "",
    caseDetails: "",
    phoneCode: "",
    phoneNumber: "",
  });
  const [selectedFile, setSelectedFile] = useState(null);

  const handleEditClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    oneditClick(profile_id, event);
  };

  const handleOpenClick = () => {
    setOpenDetailsModal(true);
    // Reset editable fields when opening modal
    setEditableFields({
      preferredCountry: "",
      preferredCity: "",
      preferredHospital: "",
      preferredDoctor: "",
      passportNumber: "",
      caseDetails: "",
      phoneCode: "",
      phoneNumber: "",
    });
  };

  const handleCloseDetailsModal = () => {
    setOpenDetailsModal(false);
    setError(null);
  };

  const handleFieldChange = (field) => (event) => {
    setEditableFields({
      ...editableFields,
      [field]: event.target.value,
    });
  };

  const handleSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    try {
      const body = {
        case_file_id: "",
        appointment_id: "",
        passport_number: editableFields?.passportNumber,
        preferred_country: editableFields?.preferredCountry,
        preferred_city: editableFields?.preferredCity,
        preferred_hospital: editableFields?.preferredHospital,
        preferred_doctor: editableFields?.preferredDoctor,
        case_details: editableFields?.caseDetails,
      };
      const res = await ServiceCall.postv2(
        `/v2/doctor/create/patient/referral/`,
        `${profile_id}`,
        body,
        {
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        }
      );
      if (res) {
        setIsLoading(false);
        handleCloseDetailsModal();
        setShowProfilesModal(false);
        fetchReferralList();
        setState({
          msg: res.data.message || "",
          type: "success",
          timer: null,
          vertical: "top",
          horizontal: "center",
        });

      }
    } catch (error) {
      setIsLoading(false)
    }
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];

    if (file) {
      setSelectedFile(file); // Update state with selected file

      // Create FormData for file upload
      const formData = new FormData();
      formData.append("patient_file", file);

      try {
        const res = await ServiceCall.postv2(
          `/v2/doctor/upload/patient/referral/files/`,
          `${phoneCode}${phone}`,
          formData,
          {
            doc_secret: Ls.get("docToken"),
            doc_key: Ls.get("docKey"),
          }
        );
        console.log("File upload success:", res.data);
      } catch (err) {
        console.error("File upload failed:", err);
      }
    }
  };

  return (
    <>
        {(
        isLoading) && (
        <Overlay opacity=".6">
          <CircularProgress size="20px" color="success" />
        </Overlay>
      )}
      <Row
        className="align-items-center bg-body shadow rounded-3 py-2 mb-3 position-relative"
        style={{ cursor: "pointer" }}
        onClick={handleOpenClick}
      >
        <Col
          xs={3}
          className="text-center text-capitalize d-flex flex-column justify-content-center align-items-center"
          style={{ float: "inline-start", marginLeft: "1rem" }}
        >
          {profileType === "self" ? (
            <PersonIcon style={{ fontSize: "3rem" }} />
          ) : (
            <GroupsIcon style={{ fontSize: "3rem" }} />
          )}
          <small style={{ fontSize: "0.8rem" }}>({profileType})</small>
        </Col>

        <Col className="d-flex flex-column" style={{ marginLeft: "10rem" }}>
          <h3 className="h5 text-capitalize m-0">
            {name} ({gender}, {age})
          </h3>
          <span style={{ fontSize: "0.92rem" }}>
            {doctorContext?.profileData?.phone}
          </span>
          <span style={{ fontSize: "0.92rem" }}>{email}</span>
        </Col>

        {/* <div
          onClick={handleEditClick}
          style={{
            cursor: "pointer",
            position: "absolute",
            top: "1rem",
            right: "1.5rem",
          }}
        >
          <BorderColorIcon />
        </div> */}
      </Row>

      <Dialog
        open={openDetailsModal}
        onClose={handleCloseDetailsModal}
        maxWidth="md"
        fullWidth
      >
        {loading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height={200}
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            <DialogTitle sx={{ m: 0, p: 2, bgcolor: "#f5f5f5" }}>
              Referral Details
              <IconButton
                onClick={handleCloseDetailsModal}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                }}
              >
                <Close />
              </IconButton>
            </DialogTitle>

            <DialogContent dividers>
              <Grid container spacing={3}>
                {/* Readonly Personal Details */}
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    label="Patient Name"
                    value={name || ""}
                    InputProps={{ readOnly: true }}
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    label="Age"
                    type="number"
                    value={age || ""}
                    InputProps={{ readOnly: true }}
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth required>
                    <InputLabel>Gender</InputLabel>
                    <Select label="Gender" value={gender || ""} disabled>
                      <MenuItem value="male">Male</MenuItem>
                      <MenuItem value="female">Female</MenuItem>
                      <MenuItem value="other">Other</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Box sx={{ display: "flex", gap: 1 }}>
                    <FormControl sx={{ width: "30%" }}>
                      <InputLabel>Code</InputLabel>
                      <Select value={phoneCode} label="Code" disabled>
                        {countriesList
                          .slice()
                          .sort((a, b) => a.code.localeCompare(b.code))
                          .map((country, index) => (
                            <MenuItem
                              key={`${country.code}-${index}`}
                              value={country.code}
                            >
                              {country.code}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                    <TextField
                      required
                      fullWidth
                      label="Phone Number"
                      type="tel"
                      value={phone || ""}
                      InputProps={{ readOnly: true }}
                      variant="outlined"
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    label="Email"
                    type="email"
                    value={email || ""}
                    InputProps={{ readOnly: true }}
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Case details – write a detailed analysis"
                    multiline
                    rows={4}
                    value={editableFields.caseDetails || ""}
                    onChange={handleFieldChange("caseDetails")}
                    placeholder="Enter any detailed analysis..."
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={12}>
                  <input
                    accept="image/*,.pdf,.doc,.docx"
                    id="file-upload"
                    type="file"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                  />
                  <label htmlFor="file-upload">
                    <Button
                      variant="outlined"
                      component="span"
                      startIcon={<CloudUpload />}
                      fullWidth
                    >
                      Upload File
                    </Button>
                  </label>
                  <Typography variant="body2" sx={{ mt: 1 }}>
                    Selected file:{" "}
                    {selectedFile ? selectedFile.name : "No file selected"}
                  </Typography>
                </Grid>

                {/* Optional Editable Fields */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Preferred Country"
                    value={editableFields.preferredCountry || ""}
                    onChange={handleFieldChange("preferredCountry")}
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Preferred City"
                    value={editableFields.preferredCity || ""}
                    onChange={handleFieldChange("preferredCity")}
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Preferred Hospital"
                    value={editableFields.preferredHospital || ""}
                    onChange={handleFieldChange("preferredHospital")}
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Preferred Doctor"
                    value={editableFields.preferredDoctor || ""}
                    onChange={handleFieldChange("preferredDoctor")}
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Passport Number"
                    value={editableFields.passportNumber || ""}
                    onChange={handleFieldChange("passportNumber")}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </DialogContent>

            <DialogActions sx={{ p: 2, bgcolor: "#f5f5f5" }}>
              <>
                <Button onClick={handleCloseDetailsModal} variant="outlined">
                  Cancel
                </Button>
                {editableFields?.caseDetails && (
                  <Button
                    onClick={handleSubmit}
                    variant="contained"
                    color="primary"
                  >
                    Submit
                  </Button>
                )}
              </>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );
}

export default ProfileCard1;
