import React, { useState, useEffect, useContext } from "react";
import { Container } from "@mui/material";
import { useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./../../styles/appointmentScreensStyle";
import Header from "../../components/Header";
import { useHistory } from "react-router-dom";
import { Ls, datePicker } from "./../../utility/utility";
import Button from "../../Tools/Button";
import { Skeleton } from "@mui/material";
import DetailsTabs from "../../components/DetailsTabs";
import { Box } from "@mui/system";
import { PatientpageContext } from "./../../context/GlobalContext";
import Footer from "../../components/Footer";
import PwaPrompt from "../../components/PwaPrompt";
import { constants } from "../../utility/constants";
import Gap from "../../Tools/Gap";
import dayjs from "dayjs";
import { ServiceCall } from "../../utility/service-call";
import PageContentLayout from "../../components/PageContentLayout";

const useStyles = makeStyles(styles);

const doctorsListDefault = {};
export default function DetailsPage(props) {
  const doctorContext = useContext(PatientpageContext);
  const classes = useStyles();
  let history = useHistory();
  const { search } = useLocation();
  const [doctorsList, setDoctorsList] = useState(doctorsListDefault);
  const [prescriptionList, setPrescriptionList] = useState("");
  
  const [appointmentList, setAppointmentList] = useState([]);
  const [homedata, setHomedata] = useState("");
  const [toggleAppointmentLists, setToggleAppointmentLists] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.history.pushState(null, null, window.location.herf);
      window.onpopstate = () => {
        window.history.go(1);
        doctorContext?.dialogController({
          open: true,
          content: (() => (
            <div className="appointment__screen__exit">
              Do you really wish to exit?
            </div>
          ))(),
          checkLoginPage: false,
          promptWindow: true,
          dialogAction: (() => (
            <div className="appointment__screen__exit__buttons">
              <Button
                handleClick={() => {
                  doctorContext.handleDialogClose();
                  Ls.remove("pt_key");
                  Ls.remove("pt_token");
                  history.push({
                    pathname: "/",
                  });
                }}
                className="mr-2"
                text="Exit"
                containerClass="m-auto appointment__screen__verify"
              ></Button>
              <Button
                handleClick={() => {
                  doctorContext.handleDialogClose();
                }}
                className="mr-2"
                text="CANCEL"
                containerClass="m-auto appointment__screen__verify"
              ></Button>
            </div>
          ))(),
          maxWidth: "sm",
          disableBackdropClick: true,
        });
      };
    }
    return () => {
      if (typeof window !== "window") {
        window.onpopstate = null;
      }
    };
  }, []);

  useEffect(() => {
    if (Ls.get("docKey") !== null && Ls.get("docToken") !== null) {
      // prescriptionDownload();
    } else {
      history.push({
        pathname: "/",
      });
    }
  }, [
    dayjs(doctorContext?.prescriptionDate?.StartDate).format("YYYY-MM-DD"),
    dayjs(doctorContext?.prescriptionDate?.EndDate).format("YYYY-MM-DD"),
  ]);


  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  const query = useQuery();

  const prescriptionDownload = () => {
    // doctorContext.setAppointmentLoader("loading");
    try {
      const prescription = ServiceCall.getv2(
        "/v2/doctor/prescriptions/list/",
        `${constants.hospitalId("hosp_id")}?start_date=${dayjs(
          doctorContext?.prescriptionDate?.StartDate
        ).format("YYYY-MM-DD")}&end_date=${dayjs(
          doctorContext?.prescriptionDate?.EndDate
        ).format("YYYY-MM-DD")}`,
        {
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        }
      ).then((list) => {
        doctorContext.setAppointmentLoader("completed")
        setPrescriptionList(list?.data?.doctor_prescriptions);
      });
    } catch (err) {
      doctorContext.setAppointmentLoader("completed")
    }
  };
  var dynamicWidth = "calc(100vh - 100px)";
  return (
    <>
    <PageContentLayout> 
      <Gap size="2.4rem" />
      <div
        className="d-flex flex-column align-items-space-between DetailsPageContainer"
        style={{ minHeight: dynamicWidth, marginTop: "-5rem" }}
      >
        <div>
          <DetailsTabs
            classes="appointment__box"
            // loader={doctorContext.appointmentLoader}
            history={history}
            doctorsList={doctorsList}
            appointmentList={appointmentList}
            // prescriptionList={prescriptionList}
            // toggleAppointmentLists={toggleAppointmentLists}
          />
          {/* )} */}
        </div>
      </div>
      </PageContentLayout>
    </>
  );
}
