import React, { useState, useContext, useEffect } from "react";
import { ServiceCall } from "../../utility/service-call";
import { PatientpageContext } from "../../context/GlobalContext";
import { countriesList, Ls } from "./../../utility/utility";
import * as Yup from "yup";
import CircularProgress from "@mui/material/CircularProgress";
import { Formik, ErrorMessage } from "formik";
import Refredpro from "../../components/Refredpro";
import {
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  IconButton,
  Box,
  Typography,
  Snackbar,
  Button,
  Alert,
} from "@mui/material";
import { CloudUpload } from "@mui/icons-material";

const Refered = ({ formData, setFormData }) => {

  const doctorContext = useContext(PatientpageContext);
  const [errors, setErrors] = useState({});
  const [data, setData] = useState([]);
  const [openList, setOpenList] = useState(false);
  const [loader, setLoader] = useState(false);
  const [refermsg, setRefermsg] = useState({});
  console.log(37383, refermsg)
  const [showData, setShowData] = useState(false);
  const [addMore, setAddMore] = useState(false);
  const setState = doctorContext.setState;
  const [editableFields, setEditableFields] = useState({
    preferredCountry: "",
    preferredCity: "",
    preferredHospital: "",
    preferredDoctor: "",
    passportNumber: "",
    caseDetails: "",
    phoneCode: "",
    phoneNumber: "",
  });
  const [selectedFile, setSelectedFile] = useState(null);
const profileid = sessionStorage.getItem('profileid');
const phone = sessionStorage.getItem('phone');
const case_file_id = sessionStorage.getItem('case_file_id');
  const appointment =
    doctorContext.appointment || JSON.parse(Ls.get("appointmentData"));


  const handleFieldChange = (field) => (event) => {
    setEditableFields({
      ...editableFields,
      [field]: event.target.value,
    });
  };

  const fetchrefrallist = async() =>{
    try{
      const res = await ServiceCall.getv2('v2/doctor/fetch/patient/prescription/referral/list/',`${appointment?.appointmentItem?.appointment_id}`,
        {
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        }
       );
       if(res){
        setRefermsg(res.data)
        setShowData(true);

       }

    }catch(err){}
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const body = {
        case_file_id: case_file_id,
        appointment_id: appointment?.appointmentItem?.appointment_id ,
        passport_number: editableFields?.passportNumber,
        preferred_country: editableFields?.preferredCountry,
        preferred_city: editableFields?.preferredCity,
        preferred_hospital: editableFields?.preferredHospital,
        preferred_doctor: editableFields?.preferredDoctor,
        case_details: editableFields?.caseDetails,
      };
      const res = await ServiceCall.postv2(
        `/v2/doctor/create/patient/referral/`,
        `${profileid}`,
        body,
        {
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        }
      );
      if (res) {
        fetchrefrallist();
        setEditableFields("");   
        setState({
          msg: res.data.message || "",
          type: "success",
          timer: null,
          vertical: "top",
          horizontal: "center",
        });

      }
    } catch (error) {
    }
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];

    if (file) {
      setSelectedFile(file); // Update state with selected file

      // Create FormData for file upload
      const formData = new FormData();
      formData.append("patient_file", file);

      try {
        const res = await ServiceCall.postv2(
          `/v2/doctor/upload/patient/referral/files/`,`${phone}`,
          
          formData,
          {
            doc_secret: Ls.get("docToken"),
            doc_key: Ls.get("docKey"),
          }
        );
        if(res){
          setState({
            msg: res.data.message || "",
            type: "success",
            timer: null,
            vertical: "top",
            horizontal: "center",
          });
        }
      } catch (err) {
        console.error("File upload failed:", err);
      }
    }
  };

  useEffect(() => {
    fetchrefrallist();   
  }, []);

  const handleDeletePrompt = async (treat, index) => {
    let newTreatPrompt = refermsg?.referral_list;
    newTreatPrompt = newTreatPrompt
      .slice(0, index)
      .concat(newTreatPrompt.slice(index + 1));
    try {
      const deleteTreatment = await ServiceCall.deletev2(
        "/v2/doctor/delete/referral/",
        `${treat?.case_id}`, // Here, case_id is passed as a parameter
        {
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        }
      );
      if (deleteTreatment) {
        fetchrefrallist();
        if (newTreatPrompt?.length === 0) {
          setAddMore(false);
        }
        setRefermsg(newTreatPrompt);
      }
    } catch (error) {}
  };

  return (
    <div className="prescription_editor">
      <div className="presctiption_prompt" style={{padding: "0"}}>
        {showData && <Refredpro treatData={refermsg?.referral_list}
                      handleDeletePrompt={handleDeletePrompt}

        />}
      </div>
      <div className="Refferel">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Case details – write a detailed analysis"
              multiline
              rows={4}
              value={editableFields.caseDetails || ""}
              onChange={handleFieldChange("caseDetails")}
              placeholder="Enter any detailed analysis..."
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12}>
            <input
              accept="image/*,.pdf,.doc,.docx"
              id="file-upload"
              type="file"
              onChange={handleFileChange}
              style={{ display: "none" }}
            />
            <label htmlFor="file-upload">
              <Button
                variant="outlined"
                component="span"
                fullWidth
              >
                Upload File
              </Button>
            </label>
            <Typography variant="body2" sx={{ mt: 1 }}>
              Selected file: {selectedFile ? selectedFile.name : "No file selected"}
            </Typography>
          </Grid>

          {/* Optional Editable Fields */}
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Preferred Country"
              variant="outlined"
              value={editableFields.preferredCountry || ""}
                    onChange={handleFieldChange("preferredCountry")}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Preferred City"
              variant="outlined"
              value={editableFields.preferredCity || ""}
              onChange={handleFieldChange("preferredCity")}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Preferred Hospital"
              value={editableFields.preferredHospital || ""}
              onChange={handleFieldChange("preferredHospital")}
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Preferred Doctor"
              value={editableFields.preferredDoctor || ""}
              onChange={handleFieldChange("preferredDoctor")}
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Passport Number"
              value={editableFields.passportNumber || ""}
              onChange={handleFieldChange("passportNumber")}
              variant="outlined"
            />
          </Grid>
        </Grid>
        
        {/* Button Container with right alignment and reduced size */}
        <Box display="flex" justifyContent="flex-end" mt={2}>
          <Button
            // variant="contained"
            // color="primary"
            size="small"
            onClick={handleSubmit}
            style={{background: "#199a8e", color: "#fff"}}
          >
            Submit
          </Button>
        </Box>
      </div>
    </div>
  );
};

export default Refered;