import { useState, useEffect, useContext } from "react";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { PatientpageContext } from "../../context/GlobalContext";
import CalendarIcon from "../../static/images/Combined-Shape.svg";
import CircularProgress from "@mui/material/CircularProgress";
import AddIcon from "@mui/icons-material/Add";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { countriesList } from "../../utility/constants";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Ls } from "../../utility/utility";
import { ServiceCall } from "../../utility/service-call";
import { constants } from "../../utility/constants";
// import ProfileCard from "../../components/ProfileCard";
import dayjs from "dayjs";
import PageContentLayout from "../../components/PageContentLayout";
import { useHistory } from "react-router-dom";
import ReferralDropdownMenu from "./ReferralDropdownMenu ";
import Overlay from "../../components/Overlay";
import ProfileCard1 from "../../components/ProfileCard1";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Modal,
  Box,
  TextField,
  Button,
  Typography,
  FormHelperText,
  Input,
  RadioGroup,
  FormControlLabel,
  Radio,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  IconButton,
} from "@mui/material";
import { Menu } from "@material-ui/core";
import { CloudUpload, Close } from "@mui/icons-material";

const Referals = () => {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#199A8E",
      fontSize: 13,
      color: theme.palette.common.white,
      padding: "12px",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 12,
      padding: "10px",
      textAlign: "start",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));
  const doctorContext = useContext(PatientpageContext);
  const history = useHistory('');
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [formDat, setFormData] = useState({
    phone: "",
  });
  const [phoneCode, setPhoneCode] = useState("+91"); // State to hold the selected country code
  const [phoneError, setPhoneError] = useState(false);
  const setState = doctorContext.setState;
  const [dialogMessage, setDialogMessage] = useState("");
  const [showProfilesModal, setShowProfilesModal] = useState(false);
  const [profilename, setProfilename] = useState("");
  const [profileage, setProfileage] = useState("");
  const [profilegen, setProfilegen] = useState("");
  const [profileid, setProfileid] = useState("");
  const [phonedata, setPhonedata] = useState("");
  const [newfile, setNewfile] = useState("");
  const [selectedProfileId, setSelectedProfileId] = useState(null);
  const [profileNotFoundError, setProfileNotFoundError] = useState("");
  const [referralList, setReferralList] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [appointmentLoader, setAppointmentLoader] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // Initial state is loading

  const [appointmentDate, setAppointmentDate] = useState({
    StartDate: dayjs(),
    EndDate: dayjs().add(2, "days"),
  });
  const profiles = phonedata?.profiles;
  const [open, setOpen] = useState(false);
  const [formDatas, setFormDatas] = useState({
    name: "",
    age: "",
    gender: "male",
    phone: "",
    email: "",
    file: null,
    country: "",
    city: "",
    hospital: "",
    doctor: "",
    passport: "",
    notes: "",
  });

  const DropdownMenu = ({ anchorEl, handleClose, prescription }) => {
    const history = useHistory();

    const navigateToRoute = (path, prescription) => {
      // const patientId = prescription.pt_profile_id;
      history.push({
        pathname: path,
        // search: `?patientId=${patientId}`,
        state: { prescription },
      });

      handleClose();
    };

    const handleMenuItemClick = (path) => {
      navigateToRoute(path, prescription);
    };

    return (
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem>Details </MenuItem>
        <MenuItem>Cancel</MenuItem>
      </Menu>
    );
  };
  // Current code (with typo)
  const hadledoctordata1 = () => {
    setOpenModal(true);
  };
  const handleDoctorData = () => {
    setOpenModal(true);
  };

  const isValidPhoneNumber = (phoneNumber) => {
    // Remove any non-numeric characters from the phone number
    const numericPhoneNumber = phoneNumber.replace(/\D/g, "");

    // Check if the phone number has exactly 10 digits and contains only numeric characters
    return numericPhoneNumber.length === 10 && /^\d+$/.test(numericPhoneNumber);
  };

  const handleChange1 = (event) => {
    const { name, value } = event.target;

    setFormData({ ...formDat, [name]: value });
    if (name === "phone") {
      if (!isValidPhoneNumber(value)) {
        // Define isValidPhoneNumber according to your validation logic
        setPhoneError(true); // Set phone number error state
      } else {
        setPhoneError(false); // Reset phone number error state
      }
    }
  };

  const resetPhoneStates = () => {
    setFormData((prevState) => ({
      ...prevState,
      phone: "", // Reset phone number
    }));
    setDialogMessage("");
    setProfileNotFoundError("");
    setPhoneError(false); // Reset phone error state
  };

  const handleCloseMo = () => {
    setOpenModal(false);
    resetPhoneStates(); // Call reset function when closing modal
  };

  const handleChanges = (event) => {
    setPhoneCode(event.target.value);
  };

  const fetchReferralList = async (startDate, endDate) => {
    setLoading(true);
    try {
      const formattedStartDate = dayjs(startDate).format("YYYY-MM-DD");
      const formattedEndDate = dayjs(endDate).format("YYYY-MM-DD");

      const response = await ServiceCall.getv2(
        "/v2/doctor/fetch/patient/referral/list/",
        `${formattedStartDate}/${formattedEndDate}`,
        {
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        }
      );

      if (response?.data) {
        setLoading(false);
        setReferralList(response.data?.referral_list);

        // setState({
        //   msg: "Referral list fetched successfully",
        //   type: "success",
        //   timer: null,
        //   vertical: "top",
        //   horizontal: "center",
        // });
      }
    } catch (err) {
      setState({
        msg: err?.response?.data?.message || "Error fetching referral list",
        type: "error",
        timer: null,
        vertical: "top",
        horizontal: "center",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchReferralList(appointmentDate.StartDate, appointmentDate.EndDate);
  }, []);

  const handleChange = (value, label) => {
    const newDates = {
      ...appointmentDate,
      [label]: value,
    };
    setAppointmentDate(newDates);

    // Fetch referral list whenever either date changes
    fetchReferralList(
      label === "StartDate" ? value : appointmentDate.StartDate,
      label === "EndDate" ? value : appointmentDate.EndDate
    );
  };
  const handleSelectProfile = async (
    profileId,
    profilename,
    proage,
    progender
  ) => {
    console.log('clickng')
    setOpen(true)
    // setOpensModal(true);
    setSelectedProfileId(profileId);
    setProfileid(profileId);
    setProfilename(profilename);
    setProfileage(proage);
    setProfilegen(progender);
    // await fetchCases(profileId);
  };

  const handlecontact = async () => {
    setLoading(true);
    setProfileNotFoundError(""); // Reset error message
    setDialogMessage(""); // Reset dialog message

    try {
      const phonefetch = await ServiceCall.getv2(
        "/v2/doctor/fetch/patient/profiles/",
        `${constants.hospitalId("hosp_id")}/${phoneCode}${formDat?.phone}`,
        {
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        }
      );

      setLoading(false);

      if (
        phonefetch?.data?.message?.includes("No patient profile can be found")
      ) {
        setDialogMessage(phonefetch.data.message);
        setProfileNotFoundError("Profile not found"); // Set error message
        setOpenModal(true);
      } else {
        setState({
          msg: phonefetch.data.message || "",
          type: "success",
          timer: null,
          vertical: "top",
          horizontal: "center",
        });
        setPhonedata(phonefetch.data);
        setShowProfilesModal(true);
        setOpenModal(false);
      }
    } catch (err) {
      setLoading(false);
      setProfileNotFoundError(""); // Reset error on different error
      setState({
        msg: err?.response?.data?.message || "",
        type: "error",
        timer: null,
        vertical: "top",
        horizontal: "center",
      });
    }
  };
  const handleClos = () => {
    setAnchorEl(null);
  };


  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormDatas((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];

    if (file) {
      setSelectedFile(file); // Update state with selected file
      
      // Create FormData for file upload
      const formData = new FormData();
      formData.append("patient_file", file);

      try {
        const res = await ServiceCall.postv2(
          `/v2/doctor/upload/patient/referral/files/` `${phoneCode}${formDat?.phone}`,
          formData,
          
             {
              doc_secret: Ls.get("docToken"),
              doc_key: Ls.get("docKey"),
            }
          
        );
        console.log("File upload success:", res.data);
      } catch (err) {
        console.error("File upload failed:", err);
      }
    }
  };

  const handleSubmit = async (e) => {
    setIsLoading(true)
    e.preventDefault();
    try {
      const body = {
        email: formDatas?.email,
        name: formDatas?.name,
        gender: formDatas?.gender,
        age: formDatas?.age,
        passport_number: formDatas?.passport,
        preferred_country: formDatas?.country,
        preferred_city: formDatas?.city,
        preferred_hospital: formDatas?.hospital,
        preferred_doctor: formDatas?.doctor,
        case_details: formDatas?.notes,
      };
      const res = await ServiceCall.postv2(
        `v2/doctor/create/patient/and/referral/`,
        `${phoneCode}${formDat?.phone}`,
        body,
        {
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        }
      );
      if (res) {
        setIsLoading(false);
        fetchReferralList();
        handleClose();
        setShowProfilesModal(false);
        setOpenModal(false);
        setFormDatas("");
        setState({
          msg: res.data.message || "",
          type: "success",
          timer: null,
          vertical: "top",
          horizontal: "center",
        });

      }
    } catch (error) {
      setIsLoading(false);
    }
  };


  const CalendarMonth = ({ label, formate, handleChange, date }) => {
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div className="newappointment__container__calender__datePicker">
          <MobileDatePicker
            label={label}
            inputFormat={formate}
            value={date}
            onChange={(value) => handleChange(value, label)}
            renderInput={(params) => <TextField {...params} />}
          />
          <img src={CalendarIcon} />
        </div>
      </LocalizationProvider>
    );
  };

  const StatusButton = ({ type, color }) => {
    const getColorByType = (type) => {
      switch (type.toUpperCase()) {
        case 'OPEN':
          return '#fcbe03'; // Yellow
        case 'ASSIGNED':
          return '#0328fc'; // Blue
        case 'TRAVELING':
          return '#03dbfc'; // Cyan
        case 'IN TREATMENT':
          return '#fcf003'; // Bright Yellow
        case 'FOLLOW-UP':
          return '#03fc0f'; // Bright Green
        default:
          return color || 'inherit';
      }
    };
  
    const buttonStyle = {
      backgroundColor: getColorByType(type),
      color: "white",
      borderRadius: "32px",
      border: "none",
      padding: "8px 16px", 
      display: "inline-block",
      textAlign: "center",
      minWidth: "100px",
    };
  
    const buttonClass = "appointment__container__status__button";
  
    return (
      <button className={buttonClass} style={buttonStyle}>
        {type}
      </button>
    );
  };
  return (
    <>
    {isLoading && (
        <Overlay opacity=".6">
          <CircularProgress size="20px" color="success" />
        </Overlay>
      )}
      <div className="">
        <div className="newappointment__container__calender__text">
          Filter the referrals by date range
        </div>
        <div className="newappointment__container__calender__date">
          <CalendarMonth
            label="StartDate"
            formate="YYYY-MM-DD"
            date={appointmentDate?.StartDate}
            handleChange={handleChange}
          />
          <CalendarMonth
            label="EndDate"
            formate="YYYY-MM-DD"
            date={appointmentDate?.EndDate}
            handleChange={handleChange}
          />
          <div
            className="appointment__date__record"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
            }}
            onClick={handleDoctorData} // Update this line
          >
            <span style={{ display: "flex", alignItems: "center" }}>
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Create"
              )}{" "}
              <AddIcon sx={{ fontSize: "25px", marginLeft: "0.5rem" }} />
            </span>
          </div>
        </div>

        <div style={{  overflowY: "auto" }} className="mt-4">
          <TableContainer component={Paper} style={{ marginBottom: ".5rem" }}>
            <Table sx={{ minWidth: 300 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">CASE#</StyledTableCell>
                  <StyledTableCell align="center">Patient Name</StyledTableCell>
                  <StyledTableCell align="center">Gender, Age</StyledTableCell>
                  <StyledTableCell align="center">Email</StyledTableCell>
                  <StyledTableCell align="center">Phone</StyledTableCell>
                  <StyledTableCell align="center">Referred On</StyledTableCell>
                  <StyledTableCell align="center">Status</StyledTableCell>
                  <StyledTableCell align="center"></StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {referralList && referralList.length > 0 ? (
                  referralList.map((row, index) => (
                    <StyledTableRow>
                      <StyledTableCell
                        align="center"
                        style={{ textAlign: "center" }}
                      >
                        {row?.case_number || ""}
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        style={{ textAlign: "center" }}
                      >
                        {row?.patient_name || ""}
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        style={{ textAlign: "center" }}
                      >
                        {row?.gender || ""}, {" "}{row?.age}
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        style={{ textAlign: "center" }}
                      >
                        {row?.email || ""}
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        style={{ textAlign: "center" }}
                      >
                        {row?.country_code}
                        {row?.phone || ""}
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        style={{ textAlign: "center" }}
                      >
                        {row?.referred_on || ""}
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        style={{ textAlign: "center" }}
                      >
                        {/* {row?.status || ""} */}
                        <StatusButton
                    type={row?.status || ""}
                    color={row?.app_status_color_code}
                  />
                      </StyledTableCell>
                      <StyledTableCell
  align="center"
  style={{ textAlign: "center", cursor: "pointer" }}
>
  <ReferralDropdownMenu row={row}
  fetchReferralList={fetchReferralList}
  />
</StyledTableCell>
                    </StyledTableRow>
                  ))
                ) : (
                  <StyledTableRow>
                    <StyledTableCell colSpan={3} align="center">
                      {/* No data available */}
                    </StyledTableCell>
                  </StyledTableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
      <Modal
        open={openModal}
        onClose={() => {
          setOpenModal(false);
          resetPhoneStates(); // Reset states when modal is closed
        }}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90%",
            maxWidth: 500,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            maxHeight: "80vh",
            overflowY: "auto",
            height: "75vh",
          }}
        >
          <ArrowBackIosIcon
            onClick={handleCloseMo}
            style={{ float: "left", cursor: "pointer" }}
          />
          <span
            style={{
              marginBottom: "1rem",
              fontSize: "17px",
              fontWeight: "500",
              display: "flex",
            }}
          >
            Referrals
          </span>
          <Box
            sx={{
              backgroundColor: "rgb(25 154 142)",
              padding: "4px",
              marginBottom: "14rem",
            }}
          >
            <Typography
              variant="h5"
              align="center"
              color="white"
              style={{ fontSize: "17px", fontWeight: "500" }}
            >
              Search by Phone No.
            </Typography>
          </Box>

          <FormControl
            variant="filled"
            style={{ width: "100%", marginBottom: "2rem" }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "-6rem",
              }}
            >
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={phoneCode}
                onChange={handleChanges}
                // disabled
                className="w-23"
              >
                {countriesList
                  .slice() // create a copy to avoid mutating original array
                  .sort((a, b) => a.code.localeCompare(b.code)) // sort alphabetically
                  .map((country, index) => (
                    <MenuItem
                      key={`${country.code}-${index}`}
                      value={country.code}
                      style={{ marginRight: "5rem  !important" }}
                    >
                      {country.code}
                    </MenuItem>
                  ))}
              </Select>

              <InputLabel
                htmlFor="phoneNumber"
                style={{ marginLeft: "71px", marginTop: "-6rem" }}
              >
                Phone Number*
              </InputLabel>
              <Input
                id="phoneNumber"
                name="phone"
                value={formDat?.phone}
                onChange={handleChange1}
                label="Phone Number"
                required
                error={phoneError}
                style={{ flex: 1, marginLeft: 6, width: "100px" }} // Adjust the margin and flex properties
              />
            </div>
            {phoneError && (
              <FormHelperText error>
                Please enter a valid phone number.
              </FormHelperText>
            )}
          </FormControl>
          <Button
            variant="contained"
            fullWidth
            style={{ background: "#199a8e", color: "#fff" }}
            onClick={handlecontact}
            disabled={loading}
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "SEARCH"
            )}
          </Button>

          {profileNotFoundError && (
            <Typography
              style={{
                color: "#ff0000",
                marginTop: "1rem",
                textAlign: "center",
                fontSize: "14px",
              }}
            >
              {profileNotFoundError}
            </Typography>
          )}

          {/* Show CREATE NEW button if dialogMessage exists */}
          {dialogMessage && (
            <Button
              fullWidth
              style={{
                background: "#199a8e",
                color: "#fff",
                marginTop: "1rem",
                padding: "10px",
                borderRadius: "4px",
              }}
              variant="contained"
              onClick={handleOpen}
            >
              CREATE NEW
            </Button>
          )}
        </Box>
      </Modal>
      <Modal
        open={showProfilesModal}
        onClose={() => setShowProfilesModal(false)}
        aria-labelledby="profiles-modal-title"
        aria-describedby="profiles-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            maxHeight: "80vh",
            overflowY: "auto",
          }}
        >
          <ArrowBackIosIcon
            onClick={() => setShowProfilesModal(false)}
            style={{ float: "left", cursor: "pointer" }}
          />
          <Box
            sx={{
              backgroundColor: "rgb(25 154 142)",
              padding: "4px",
              marginBottom: "2rem",
            }}
          >
            <Typography
              variant="h5"
              align="center"
              color="white"
              style={{ fontSize: "17px", fontWeight: "500" }}
            >
              {" "}
              Select from available profiles for referrals
            </Typography>
          </Box>
          {profiles &&
            profiles.map((profile) => (
              <ProfileCard1
                key={profile.profile_id}
                name={profile.name}
                gender={profile.gender}
                age={profile.age}
                email={profile.email}
                profile_id={profile.profile_id}
                profileType={profile.profile_type}
                phone={formDat?.phone}
                phoneCode={phoneCode}
                setShowProfilesModal={setShowProfilesModal}
                fetchReferralList={fetchReferralList}
              />
            ))}
          <Button
            variant="contained"
            // color="#199a8e"
            fullWidth
            style={{ background: "#199a8e", color: "#fff" }}
            // onClick={handlecontacts}
            disabled={loading} // Disable button when loading is true
            onClick={() => setOpen(true)}
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Add New"
            )}
          </Button>
        </Box>
      </Modal>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle sx={{ m: 0, p: 2, bgcolor: "#f5f5f5" }}>
          Patient Registration
          <IconButton
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>

        <form onSubmit={handleSubmit}>
          <DialogContent dividers>
            <Grid container spacing={3}>
              {/* Required Fields */}
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  label="Patient Name"
                  name="name"
                  value={formDatas.name}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  label="Age"
                  name="age"
                  type="number"
                  value={formDatas.age}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth required>
                  <InputLabel>Gender</InputLabel>
                  <Select
                    label="Gender"
                    name="gender"
                    value={formDatas.gender}
                    onChange={handleInputChange}
                  >
                    <MenuItem value="male">Male</MenuItem>
                    <MenuItem value="female">Female</MenuItem>
                    <MenuItem value="other">Other</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Box sx={{ display: "flex", gap: 1 }}>
                  <FormControl sx={{ width: "30%" }}>
                    <InputLabel>Code</InputLabel>
                    <Select
                      value={phoneCode}
                      onChange={handleChanges}
                      label="Code"
                      size="medium"
                    >
                      {countriesList
                        .slice()
                        .sort((a, b) => a.code.localeCompare(b.code))
                        .map((country, index) => (
                          <MenuItem
                            key={`${country.code}-${index}`}
                            value={country.code}
                          >
                            {country.code}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  <TextField
                    required
                    fullWidth
                    label="Phone Number"
                    name="phone"
                    type="tel"
                    value={formDat.phone}
                    // onChange={handleInputChange}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  label="Email"
                  name="email"
                  type="email"
                  value={formDatas.email}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  required
                  label="Case details – write a detailed analysis"
                  name="notes"
                  value={formDatas.notes}
                  onChange={handleInputChange}
                  multiline
                  rows={4}
                  placeholder="Enter any detailed analysis..."
                />
              </Grid>
              {/* File Upload */}
              <Grid item xs={12}>
              <input
                accept="image/*,.pdf,.doc,.docx"
                id="file-upload"
                type="file"
                onChange={handleFileChange}
                style={{ display: "none" }}
              />
              <label htmlFor="file-upload">
                <Button
                  variant="outlined"
                  component="span"
                  startIcon={<CloudUpload />}
                  fullWidth
                >
                  Upload File
                </Button>
              </label>
                <Typography variant="body2" sx={{ mt: 1 }}>
                Selected file: {selectedFile ? selectedFile.name : "No file selected"}
                </Typography>
            
            </Grid>

              {/* Optional Fields */}
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Preferred Country (Optional)"
                  name="country"
                  value={formDatas.country}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Preferred City (Optional)"
                  name="city"
                  value={formDatas.city}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Preferred Hospital (Optional)"
                  name="hospital"
                  value={formDatas.hospital}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Preferred Doctor (Optional)"
                  name="doctor"
                  value={formDatas.doctor}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Passport number (Optional)"
                  name="passport"
                  value={formDatas.passport}
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions sx={{ p: 2, bgcolor: "#f5f5f5" }}>
            <Button onClick={handleClose} variant="outlined">
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              sx={{
                bgcolor: "#199a8e",
                "&:hover": {
                  bgcolor: "#168577",
                },
              }}
            >
              Submit
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};
export default Referals;
