import { useContext, useEffect, useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Modal,
  Box,
  Button,
  TextField,
  Typography,
  FormHelperText,
  Input,
} from "@mui/material";

import { PatientpageContext } from "../../context/GlobalContext";
import { ServiceCall } from "../../utility/service-call";
import { Ls } from "../../utility/utility";
import { constants } from "../../utility/constants";
import CircularProgress from "@mui/material/CircularProgress";
import Overlay from "../../components/Overlay";
import { useHistory } from "react-router-dom";

function Payment(props) {
  const {
    selectedAppointmentType,
    slectslots,
    profilename,
    appointmentid,
    amounts,
    setopen,
    isWalkIn,
  } = props;

  const [paymentMode, setPaymentMode] = useState("cash");
  const doctorContext = useContext(PatientpageContext);
  const setState = doctorContext.setState;
  const [isLoading, setIsLoading] = useState(false); // Initial state is loading
  const [amount, setAmount] = useState("");
  const [showAmtError, setShowAmtError] = useState(false);
  const [confirming, setConfirming] = useState(false);
  const [processingOnlinePayment, setProcessingOnlinePayment] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastType, setToastType] = useState("");
  const [toastMessage, setToastMessage] = useState("");
  const [closeForm, setCloseForm] = useState(false);
  const [verifyingRazorpayPayment, setVerifyingRazorpayPayment] =
    useState(false);
  const history = useHistory("");

  const handleAmountChange = (event) => {
    const enteredAmount = event.target.value;
    setAmount(enteredAmount);

    // Check if the entered amount matches the amount due
    if (enteredAmount && parseFloat(enteredAmount) !== parseFloat(amounts)) {
      setShowAmtError(true);
    } else {
      setShowAmtError(false);
    }
  };

  const initiateOrder = async (payementType) => {
    setIsLoading(true);
    try {
      const confirming = await ServiceCall.postv2(
        "/v2/doctor/initiate/appointment/order/",
        `${constants.hospitalId("hosp_id")}/${appointmentid}/${payementType}`,
        "",
        {
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        }
      );
      if (confirming) {
        setIsLoading(false);

        await handleConfirm();
      }
    } catch (err) {}
  };

  const handleConfirm = async () => {
    setIsLoading(true);

    try {
      const Confirmpay = await ServiceCall.postv2(
        "/v2/doctor/appointment/booking/cash/payment/confirmation/",
        `${appointmentid}`,
        {
          payment_mode: paymentMode,
          amount_paid: +amount,
          currency: "INR", // You might want to replace this with the correct currency
        },
        {
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        }
      );
      if (Confirmpay) {
        setopen(false);
        setIsLoading(false);
        setState({
          msg: Confirmpay?.data.message || "",
          type: "success",
          timer: null,
          vertical: "top",
          horizontal: "center",
        });

        history.push({
          pathname: "/home/0",
        });

        setTimeout(() => {
          history.replace("/home");
        }, 0);

        doctorContext.getAppointment();
      }

      console.log("Payment confirmation response:", Confirmpay);
    } catch (err) {
      console.error("Error confirming payment:", err);
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading && (
        <Overlay opacity=".6">
          <CircularProgress size="20px" color="success" />
        </Overlay>
      )}
      <div className="text-center w-100">
        <p className="mb-1">
          Booking for:{" "}
          <span
            className="text-decoration-underline fw-bold text-capitalize"
            style={{ textDecoration: "underline", fontWeight: "500" }}
          >
            {profilename}
          </span>
        </p>
        <p className="mb-1">
  {isWalkIn ? (
    <span 
      className="text-decoration-underline fw-bold text-capitalize"
      style={{ textDecoration: "underline", fontWeight: "500" }}
    >
      Walk-in Appointment
    </span>
  ) : (
    <>
      <span 
        className="text-decoration-underline fw-bold text-capitalize"
        style={{ textDecoration: "underline", fontWeight: "500" }}
      >
        {selectedAppointmentType}
      </span>{" "}
      Appointment at{" "}
      <span
        className="text-decoration-underline fw-bold text-capitalize"
        style={{ textDecoration: "underline", fontWeight: "500" }}
      >
        {slectslots?.end_time}
      </span>{" "}
      (
      <span
        className="text-decoration-underline fw-bold text-capitalize"
        style={{ textDecoration: "underline", fontWeight: "500" }}
      >
        {slectslots?.schedule_date}
      </span>
      )
    </>
  )}
</p>
        <p className="mb-1">
          With{" "}
          <span
            className="text-decoration-underline fw-bold text-capitalize"
            style={{ textDecoration: "underline", fontWeight: "500" }}
          >
            {doctorContext?.profileData?.title}
            {"."}
            {doctorContext?.profileData?.name}
          </span>
        </p>
        <p className="my-2">
          Amount Due: ₹
          <span
            className="fw-bold text-capitalize"
            style={{ textDecoration: "underline", fontWeight: "500" }}
          >
            {amounts}
          </span>
        </p>

        {selectedAppointmentType !== "online" && (
          <div>
            <p>Confirm Cash/Card (Direct) Payment</p>
            <select
              defaultValue="cash"
              onChange={(event) => {
                setPaymentMode(event.target.value);
              }}
              style={{
                border: "2px solid #b3c6e7",
                borderRadius: "0",
                padding: "6.5px",
                margin: "0",
                height: "2.5rem",
              }}
            >
              <option value="cash">Cash</option>
              <option value="upi">UPI</option>
              <option value="card">Debit/Credit Card</option>
              <option value="wallet">Wallet</option>
            </select>
            <input
              type="text"
              className="mx-2 px-2 mt-3 mt-md-0"
              placeholder="Amount Paid (INR)"
              value={amount}
              onChange={handleAmountChange}
              style={{
                border: "2px solid #b3c6e7",
                borderRadius: "0",
                height: "2.5rem",
                padding: "0 4px",
              }}
            />

            <small
              className="text-danger d-block w-100 text-center"
              style={{ color: "red" }}
            >
              {showAmtError
                ? "Entered amount does not match the amount due."
                : "   "}
            </small>
            <Button
              className="w-100 my-3 rounded-3"
              onClick={() => initiateOrder("cash")}
              disabled={confirming || showAmtError}
              style={{ background: "#199a8e", color: "#fff" }}
            >
              {confirming ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="mx-auto"
                />
              ) : (
                "Confirm"
              )}
            </Button>
          </div>
        )}

        {/* {verifyingRazorpayPayment && (
        <div className="whole-screen-backdrop d-flex justify-content-center align-items-center">
          <div className="text-white">
            <Spinner
              as="span"
              animation="border"
              size="lg"
              role="status"
              aria-hidden="true"
              className="m-auto text-white fs-1 d-block"
            />
            <p className="fs-4">Verifying Payment...</p>
          </div>
        </div>
      )} */}
      </div>
    </>
  );
}

export default Payment;
