import * as React from "react";
import { useState, useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Header from "./Header";
import { useHistory, useLocation } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ServiceCall } from "../utility/service-call";
import { Ls } from "../utility/utility";
import { constants } from "../utility/constants";
import Appointmentsvg from "../../src/static/images/my_appointments.svg";
import Prescriptionsvg from "../../src/static/images/doc_prescription.svg";
import doctorprofile from "../../src/static/images/profile-svgrepo-com.svg";
import Mypatientsvg from "../../src/static/images/health-care-doctor-svgrepo-com.svg";
import doctorreposvg from "../../src/static/images/message-square-dots-svgrepo-com.svg";
import Experiencesvg from "../../src/static/images/rating-rate-svgrepo-com-3.svg";
import clocksvg from "../../src/static/images/clock-svgrepo-com.svg";
import trendssvg from "../../src/static/images/insights-svgrepo-com.svg";
import Precrptions from "../../src/static/images/prescription.png";
import doctorlogo from "../static/images/whitedoctor.png";
import Referalsvg from "../../src/static/images/referral.png";
import ShareIcon from "@mui/icons-material/Share";
const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const routes = {
  "My Appointments": "/home",
  "My Prescriptions": "/my-prescriptions",
  "My Profile": "/my-profile",
  "My Patients": "/my-patients",
  "My Messages": "/my-messages",
  "Patient Experience": "/patient-experience",
  "My Schedule": "/my-schedule",
  "Trends": "/trends",
  "Custom Templates": "/custom-templates",
  "Referrals": "/referrals",
};

const Sidenav = ({ children, pageTitle, props }) => {
  const history = useHistory();
  const location = useLocation();
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const [homdata, setHomedata] = useState("");
  const [showLoader, setShowLoader] = useState(true);
  const [openDropdown, setOpenDropdown] = useState(null);

  const Loader = () => (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <CircularProgress />
    </div>
  );

  const iconComponents = {
    "My Appointments": Appointmentsvg,
    "My Prescriptions": Prescriptionsvg,
    "My Profile": doctorprofile,
    "My Patients": Mypatientsvg,
    "My Messages": doctorreposvg,
    "Patient Experience": Experiencesvg,
    "My Schedule": clocksvg,
    Trends: trendssvg,
    "Custom Templates": Precrptions,
    Referrals: Referalsvg,
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const getHome = async () => {
      setShowLoader(true);

      try {
        const homie = await ServiceCall.getv2(
          "/v2/doctor/app/home/",
          `${constants.hospitalId("hosp_id")}`,
          {
            doc_secret: Ls.get("docToken"),
            doc_key: Ls.get("docKey"),
          }
        );
        if (homie) {
          setShowLoader(false);
          // const buttonList = homie?.data?.icons;
          // const excludedButtons = ["Patient History","Share", "View/Generate"];
          // const filteredButtonList = buttonList.filter(
          //   (bl) => !excludedButtons.includes(bl.display_name)
          // );

          setHomedata(homie.data);
        }
      } catch (err) {
        setShowLoader(false);
      }
    };
    getHome();
  }, []);

  const handleButtonClick = (icon) => {
    const path = routes[icon.title];
    if (path) {
      history.push(path);
      setOpenDropdown(openDropdown === icon.title ? null : icon.title);
    }
  };
  return (
    <>
      {showLoader ? (
        <Loader />
      ) : (
        <Box position="relative" sx={{ display: "flex" }}>
          <CssBaseline />
          <AppBar position="fixed">
            <Toolbar style={{ background: "#199a8e" }}>
              <Typography
                variant="h6"
                noWrap
                component="div"
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "-1.5rem",
                }}
              >
                <img
                  src={doctorlogo}
                  alt="Doctor Logo"
                  style={{
                    height: "73px",
                  }}
                />
              </Typography>
              <Header page="Detailspage" props={props} />
            </Toolbar>
          </AppBar>
          <Drawer variant="permanent" open={open}>
            <DrawerHeader></DrawerHeader>
            <Divider style={{ background: "rgb(25 154 142)" }} />
            {homdata && homdata.icons && Array.isArray(homdata.icons) && (
              <List>
                {homdata.icons
                  // .filter((icon) => icon.title !== "My Messages") // Filter out "My Messages" option
                  .map((icon, index) => (
                    <ListItem
                      key={index}
                      disablePadding
                      sx={{ display: "block" }}
                      onClick={() => handleButtonClick(icon)}
                      style={{
                        padding: 0,
                        position: "relative",
                        backgroundColor:
                          location.pathname === routes[icon.title]
                            ? "rgb(143 196 187)"
                            : "transparent",
                      }}
                    >
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? "initial" : "center",
                          px: 2.5,
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                          }}
                        >
                          <img
                            src={iconComponents[icon.title]}
                            alt={icon.title}
                            style={{
                              width: "24px",
                              height: "24px",
                            }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary={icon.title}
                          sx={{ opacity: open ? 1 : 0 }}
                        />
                      </ListItemButton>
                      {icon.hasDropdown && (
                        <Box
                          sx={{
                            position: "absolute", // Positioned absolutely within ListItem
                            top: "100%", // Positioned below the ListItem
                            left: 0,
                            width: "100%",
                            backgroundColor: "white",
                            boxShadow: theme.shadows[4],
                          }}
                        >
                          {/* Render dropdown content here */}
                        </Box>
                      )}
                    </ListItem>
                  ))}
              </List>
            )}
            <ListItem>
              <IconButton
                color="inherit"
                aria-label={open ? "close drawer" : "open drawer"}
                onClick={open ? handleDrawerClose : handleDrawerOpen}
                edge="start"
                style={{ marginLeft: "1px" }}
              >
                {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
              </IconButton>
            </ListItem>
            <Divider />
          </Drawer>
          <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            <DrawerHeader />
            {children}
          </Box>
        </Box>
      )}
    </>
  );
};

export default Sidenav;
