import React from "react";
import {
  Grid,
  FormControl,
  TextField,
} from "@mui/material";
import { convertTime } from "../utility/utility";
import dayjs from "dayjs";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { DemoItem } from "@mui/x-date-pickers/internals/demo";
import Switch from "../Tools/Swtich";

const InputSchedule = ({
  startTime,
  endTime,
  isAvailable,
  onHandleChange,
  selectedDate,
  label,
  slotsDetails,
  onToogleChange,
  defaultSlotsDetails,
  isChecked,
  icon,
}) => {
  return (
    <Grid
      container
      xs={5}
      alignItems="center"
      className="mt-3 px-3 mx-3 pt-2 pb-2 pos-relative"
      sx={{ border: "1px solid #cccccc", borderRadius: "0.6rem" }}
    >
      <Grid item xs={2} className="pt-0">
        <Switch
          label=""
          labelInput=""
          checked={isChecked}
          ContainerClass="mobileTimerPicker__switch"
          handleChange={(e) => {
            onToogleChange(e, label);
          }}
        />
        {icon}
      </Grid>
      <Grid container xs={10} className="pt-0" justifyContent="space-around">
        <Grid item xs={5}>
          <FormControl className="w-100" variant="standard">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MobileTimePicker
  value={dayjs(`${selectedDate}T${startTime.replace(".", ":")}`)}

                className="mobileTimerPicker"
                label="Start Time"
                format="hh:mm"
                minTime={dayjs(
                  `${
                    selectedDate +
                    "T" +
                    defaultSlotsDetails[`${label}_start_time`]?.replace(
                      ".",
                      ":"
                    )
                  }`
                )}
                maxTime={dayjs(
                  `${
                    selectedDate +
                    "T" +
                    defaultSlotsDetails[`${label}_max_time`]?.replace(".", ":")
                  }`
                )}
                onChange={(e) =>
                  onHandleChange(e, "startTimeRec", `${label}_start_time`)
                }
                renderInput={(params) => <TextField {...params} />}
                disabled={!isChecked || isAvailable}
              />
            </LocalizationProvider>
          </FormControl>
        </Grid>
        <Grid item xs={5}>
          <FormControl variant="standard" className="w-100">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MobileTimePicker
  value={dayjs(`${selectedDate}T${endTime.replace(".", ":")}`)}
                label="End Time"
                className="mobileTimerPicker"
                format="hh:mm"
                minTime={dayjs(
                  `${
                    selectedDate +
                    "T" +
                    slotsDetails[`${label}_start_time`]?.replace(".", ":")
                  }`
                )}
                maxTime={dayjs(
                  `${
                    selectedDate +
                    "T" +
                    defaultSlotsDetails[`${label}_end_time`]?.replace(".", ":")
                  }`
                )}
                onChange={(e) =>
                  onHandleChange(e, "startTimeRec", `${label}_end_time`)
                }
                renderInput={(params) => <TextField {...params} />}
                disabled={!isChecked || isAvailable}
              />
            </LocalizationProvider>
          </FormControl>
        </Grid>
      </Grid>
      <div className="mobileTimerPicker__label">{label} Schedule</div>
    </Grid>
  );
};

export default InputSchedule;
