import { useState, useContext, useEffect } from "react";
import { PatientpageContext } from "../../context/GlobalContext";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CalendarMonth from "../../Tools/CalendarMonth";
import dayjs from "dayjs";
import { constants } from "../../utility/constants";
import { ServiceCall } from "../../utility/service-call";
import { Ls } from "../../utility/utility";
import PageContentLayout from "../../components/PageContentLayout";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import CalendarIcon from "../static/images/Combined-Shape.svg";
import CalendarIcon from "../../static/images/Combined-Shape.svg";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import NotesIcon from "@mui/icons-material/Notes";
import SearchIcon from "../../static/images/svg/SearchIcon";
import { useHistory } from "react-router-dom";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Menu } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Overlay from "../../components/Overlay";

import {
  TextField,
  Button,
  Container,
  makeStyles,
  FormControl,
  InputLabel,
  InputAdornment,
  Input,
  MenuItem,
  Select,
  Modal,
  Typography,
  Grid,
  IconButton,
  Box,
  FormHelperText,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  formControl: {
    // Add styles for the FormControl component
    width: "36%",
    marginTop: "18px",
    marginLeft: "42px",
  },
  nameContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  button: {
    margin: theme.spacing(2, 0),
    backgroundColor: "#199a8e",
    "&:hover": {
      backgroundColor: "#199a8e",
    },
  },
  filterContainer: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(2),
    flexWrap: "wrap",
    marginTop: theme.spacing(2),
  },
  filterSelect: {
    width: "200px", // Set a fixed or max width
  },
  filterInput: {
    width: "200px", // Set a fixed or max width
  },
  applyButton: {
    height: "54px",
    width: "94px",
  },
}));

const DropdownMenu = ({ anchorEl, handleClose, prescription }) => {
  const history = useHistory();

  const navigateToRoute = (path, prescription) => {
    console.log(478999, prescription?.pt_profile_id)
    // const patientId = prescription.pt_profile_id;
    history.push({
      pathname: path,
      // search: `?patientId=${patientId}`,
      state: { prescription },
    });

    handleClose();
  };

  const handleMenuItemClick = (path) => {
    navigateToRoute(path, prescription);
  };

  return (
    <Menu
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      <MenuItem onClick={() => handleMenuItemClick("/Patient-details")}>
        Appointments
      </MenuItem>
      <MenuItem onClick={() => handleMenuItemClick("/my-notes")}>
        Notes
      </MenuItem>
      <MenuItem onClick={() => handleMenuItemClick("/medical-records")}>
        Medical Records
      </MenuItem>
      <MenuItem onClick={() => handleMenuItemClick("/vitals")}>Vitals</MenuItem>
    </Menu>
  );
};

const Mypatient = () => {
  const doctorContext = useContext(PatientpageContext);
  const classes = useStyles();
  const [filterValues, setFilterValues] = useState({
    filter: "",
    filter_value: "",
  });
  const [appointmentLi, setAppointmentList] = useState([]);
  const [searchData, setSearchData] = useState("");
  const [patientdata, setPatientdata] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [isLoading, setIsLoading] = useState(true); // Initial state is loading
  const [selectedPatient, setSelectedPatient] = useState(null);

  const startDate = doctorContext?.prescriptionDate?.StartDate;
  const patientda = doctorContext?.patientdat;

  const handlesub = (event, patient) => {
    setAnchorEl(event.currentTarget);
    setSelectedPatient(patient);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (patientda) {
      setAppointmentList(patientda || []);
    }
  }, [patientda]);

  useEffect(() => {
    const Getmypatient = async () => {
      try {
        const response = await ServiceCall.newget("/facility/pt/data/filters");
        if (response && response.data && response.data.records) {
          setIsLoading(false);
          setPatientdata(response.data.records);
        }
      } catch (err) {}
    };
    Getmypatient();
  }, []);

  const handleFilterChange = (event) => {
    const selectedValue = event.target.value;
    setFilterValues((prevState) => ({
      ...prevState,
      filter: selectedValue,
    }));
  };

  const handleTextFilterChange = (event) => {
    const textValue = event.target.value;
    setFilterValues((prevState) => ({
      ...prevState,
      filter_value: textValue,
    }));
  };

  const handleDateChange = async (value, label) => {
    setIsLoading(true);
    let start =
      label === "StartDate" ? value : doctorContext?.patientDate?.StartDate;
    let end = label === "EndDate" ? value : doctorContext?.patientDate?.EndDate;
    try {
      await doctorContext.getPatientdata(start, end);
      doctorContext?.setPatientDate({
        ...doctorContext?.patientDate,
        [label]: value,
      });
    } catch (err) {
      console.error("Error fetching patient data:", err);
    } finally {
      setIsLoading(false); // Set isLoading to false after the API call is completed
    }
  };

  const handleAddService = async () => {
    setIsLoading(true); // Set isLoading to true before making the API call

    let start = doctorContext?.patientDate?.StartDate;
    let end = doctorContext?.patientDate?.EndDate;

    try {
      await doctorContext.getPatientdata(start, end, filterValues);
    } catch (err) {
      console.error("Error fetching patient data:", err);
    } finally {
      setIsLoading(false); // Set isLoading to false after the API call is completed
    }
  };
  const handleSearch = (e) => {
    const value = e.target.value;
    let appointmentListUpdated = patientda;

    const updatedAppointmentList =
      Array.isArray(appointmentListUpdated) &&
      appointmentListUpdated.length &&
      appointmentListUpdated.filter(
        (item) =>
          item.patient_name
            .toLowerCase()
            .includes(value.trim().toLowerCase()) ||
          item.patient_age.toString().includes(value.trim()) ||
          item.patient_gender.toLowerCase().includes(value.trim().toLowerCase())
      );

    setAppointmentList(updatedAppointmentList);
    setSearchData(value);
  };

  const CalendarMonth = ({ label, formate, handleChange, date }) => (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className="newappointment__container__calender__datePicker">
        <MobileDatePicker
          label={label}
          inputFormat={formate}
          value={date}
          onChange={(value) => handleChange(value, label)}
          renderInput={(params) => <TextField {...params} />}
        />
        <img src={CalendarIcon} alt="Calendar Icon" />
      </div>
    </LocalizationProvider>
  );

  return (
    <>
      {isLoading && (
        <Overlay opacity=".6">
          <CircularProgress size="20px" color="success" />
        </Overlay>
      )}
      <PageContentLayout>
        <div style={{ margin: "0px" }}>
          <div
            className="prescriptionContainer__heading"
            style={{ marginLeft: "3rem" }}
          >
            Filter the patients by registered between start and end date.
          </div>
          <div
            className="prescriptionContainer__dates"
            style={{ marginLeft: "3rem", caretColor: "transparent" }}
          >
                   <CalendarMonth
              label="StartDate"
              formate="YYYY-MM-DD"
              date={doctorContext?.patientDate?.StartDate}
              handleChange={handleDateChange}
  style={{
    '& .MuiInputLabel-root': {
      marginLeft: '19px'
    }
  }}
/>
            <CalendarMonth
              label="EndDate"
              formate="YYYY-MM-DD"
              date={doctorContext?.patientDate?.EndDate}
              handleChange={handleDateChange}
            />
          </div>

          <div
            className="prescriptionContainer__heading"
            style={{ width: "42%", marginLeft: "3rem", caretColor: "transparent" }}
          >
            Filter the patients list using Advance Search option, select Adv.
            Search by drop down, enter text filter and click ‘Apply’
          </div>
          <div style={{ margin: "2px", display: "flex", flexDirection: "row" }}>
            <FormControl
              variant="filled"
              className={classes.formControl}
              style={{ width: "36%", marginTop: "18px", marginLeft: "42px" }}
            >
              <InputLabel id="salutation-label">Adv. Search</InputLabel>
              <Select
                labelId="salutation-label"
                id="salutation"
                name="title"
                value={filterValues.filter}
                autoWidth
                onChange={handleFilterChange}
              >
                {patientdata &&
                  patientdata.map((item) => (
                    <MenuItem key={item.filter} value={item.filter}>
                      {item.filter_display}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <TextField
              id="outlined-basic"
              variant="outlined"
              style={{ width: "32%", margin: "16px" }}
              value={filterValues.filter_value}
              onChange={handleTextFilterChange}
            />
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleAddService}
              style={{ height: "54px", width: "94px" }}
              disabled={isLoading} // Disable button when loading is true
            >
              {isLoading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Apply"
              )}
            </Button>
          </div>
          <div className="prescriptionContainer__search">
            <input
              type="text"
              placeholder="Search for Name, Age or Gender"
              onChange={(e) => handleSearch(e)}
              value={searchData}
            />
          </div>
        </div>

        <div
          className="py-2 px-3 appointment__container"
          style={{ caretColor: "transparent" }}
        >
          <Table>
            {Array.isArray(appointmentLi) && appointmentLi.length > 0 && (
              <TableHead>
                <TableRow>
                  <TableCell style={{ textAlign: "center" }}>
                    Patient Name
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>Gender</TableCell>
                  <TableCell style={{ textAlign: "center" }}>Age</TableCell>
                  <TableCell style={{ textAlign: "center" }}>Email</TableCell>
                  <TableCell style={{ textAlign: "center" }}>Phone</TableCell>
                  <TableCell style={{ textAlign: "center" }}></TableCell>
                </TableRow>
              </TableHead>
            )}
            <TableBody>
              {Array.isArray(appointmentLi) && appointmentLi.length > 0
                ? appointmentLi.map((prescription, index) => (
                    <TableRow key={index}>
                      <TableCell style={{ textAlign: "center" }}>
                        {prescription.patient_name}
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        {prescription.patient_gender === "Female"
                          ? "F"
                          : "M" || "NA"}
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        {prescription.patient_age}
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        {prescription.email}
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        {prescription.phone}
                      </TableCell>
                      <TableCell
                        style={{ textAlign: "center", cursor: "pointer" }}
                        onClick={(event) => handlesub(event, prescription)} // Pass the prescription object
                      >
                        <MoreVertIcon />
                      </TableCell>
                      <DropdownMenu
                        anchorEl={anchorEl}
                        handleClose={handleClose}
                        prescription={selectedPatient}
                      />
                    </TableRow>
                  ))
                : null}
            </TableBody>
          </Table>
        </div>
      </PageContentLayout>
    </>
  );
};

export default Mypatient;
