import React, { useState } from "react";
import {
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  IconButton,
  Box,
  Typography,
  Snackbar,
  Alert,
  CircularProgress,
} from "@mui/material";
import { Close, MoreVert, CloudUpload } from "@mui/icons-material";
import { useHistory } from "react-router-dom";
import { ServiceCall } from "../../utility/service-call";
import { Ls } from "../../utility/utility";
import { countriesList } from "../../utility/utility";

const ReferralDropdownMenu = ({ row, fetchReferralList }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [openDetailsModal1, setOpenDetailsModal1] = useState(false);

  const [refer, setRefer] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const history = useHistory();

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleCloseDetailsModal = () => {
    setOpenDetailsModal(false);
    setError(null);
  };

  const handleDetailsClick = async () => {
    setLoading(true);
    setError(null);
    try {
      const res = await ServiceCall.getv2(
        `/v2/doctor/fetch/patient/and/referral/details/`,
        `${row.case_id}`,
        {
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        }
      );

      if (res && res.data) {
        setRefer({
          name: res.data.name || 'N/A',
          age: res.data.age || '',
          gender: res.data.gender || '',
          country_code: res.data.country_code || '',
          phone: res.data.phone || '',
          email: res.data.email || '',
          case_details: res.data.case_details || '',
          preferred_country: res.data.preferred_country || '',
          preferred_city: res.data.preferred_city || '',
          preferred_hospital: res.data.preferred_hospital || '',
          preferred_doctor: res.data.preferred_doctor || '',
          passport_number: res.data.passport_number || '',
          file: res.data.file || null,
          case_number: res.data.case_number || "",
        });
        setOpenDetailsModal(true);
      } else {
        setError("No referral details found");
      }
    } catch (err) {
      console.error("Error fetching referral details:", err);
      setError("Failed to fetch referral details. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleCancelClick = async () => {
    try {
      const res = await ServiceCall.deletev2(
        `/v2/doctor/delete/referral/`,
        `${row.case_id}`,
        {
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        }
      );
      if (res) {
        fetchReferralList();
        handleCloseMenu();
      }
    } catch (err) {
      console.error("Error canceling referral:", err);
      setError("Failed to cancel referral. Please try again.");
    }
  };

  const handleFileView = () => {
    // Implement file view/download logic
    // This might involve calling an API to fetch the file
    if (refer.file) {
      console.log("View file:", refer.file);
      // Example: window.open(fileUrl, '_blank');
    }
  };

  const handleCloseError = () => {
    setError(null);
  };



  return (
    <>
      <MoreVert onClick={handleOpenMenu} style={{ cursor: "pointer" }} />
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        <MenuItem onClick={handleDetailsClick}>Details</MenuItem>
        <MenuItem onClick={handleCancelClick}>Cancel</MenuItem>

      </Menu>

      <Dialog
        open={openDetailsModal}
        onClose={handleCloseDetailsModal}
        maxWidth="md"
        fullWidth
      >
        {loading ? (
          <Box 
            display="flex" 
            justifyContent="center" 
            alignItems="center" 
            height={200}
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            <DialogTitle sx={{ m: 0, p: 2, bgcolor: "#f5f5f5" }}>
              Referral Details ({refer?.case_number || ""})
              <IconButton
                onClick={handleCloseDetailsModal}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                }}
              >
                <Close />
              </IconButton>
            </DialogTitle>

            <DialogContent dividers>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    label="Patient Name"
                    value={refer.name || ''}
                    InputProps={{ readOnly: true }}
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    label="Age"
                    type="number"
                    value={refer.age || ''}
                    InputProps={{ readOnly: true }}
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth required>
                    <InputLabel>Gender</InputLabel>
                    <Select
                      label="Gender"
                      value={refer.gender || ''}
                      disabled
                    >
                      <MenuItem value="male">Male</MenuItem>
                      <MenuItem value="female">Female</MenuItem>
                      <MenuItem value="other">Other</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Box sx={{ display: "flex", gap: 1 }}>
                    <FormControl sx={{ width: "30%" }}>
                      <InputLabel>Code</InputLabel>
                      <Select
                        value={refer.country_code || ''}
                        label="Code"
                        disabled
                      >
                        {countriesList
                          .slice()
                          .sort((a, b) => a.code.localeCompare(b.code))
                          .map((country, index) => (
                            <MenuItem
                              key={`${country.code}-${index}`}
                              value={country.code}
                            >
                              {country.code}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                    <TextField
                      required
                      fullWidth
                      label="Phone Number"
                      type="tel"
                      value={refer.phone || ''}
                      InputProps={{ readOnly: true }}
                      variant="outlined"
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    label="Email"
                    type="email"
                    value={refer.email || ''}
                    InputProps={{ readOnly: true }}
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Case details – write a detailed analysis"
                    multiline
                    rows={4}
                    value={refer.case_details || ''}
                    placeholder="Enter any detailed analysis..."
                    InputProps={{ readOnly: true }}
                    variant="outlined"
                  />
                </Grid>

          

                {/* Optional Fields */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Preferred Country"
                    value={refer.preferred_country || ''}
                    InputProps={{ readOnly: true }}
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Preferred City"
                    value={refer.preferred_city || ''}
                    InputProps={{ readOnly: true }}
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Preferred Hospital"
                    value={refer.preferred_hospital || ''}
                    InputProps={{ readOnly: true }}
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Preferred Doctor"
                    value={refer.preferred_doctor || ''}
                    InputProps={{ readOnly: true }}
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Passport Number"
                    value={refer.passport_number || ''}
                    InputProps={{ readOnly: true }}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </DialogContent>

            <DialogActions sx={{ p: 2, bgcolor: "#f5f5f5" }}>
              <Button 
                onClick={handleCloseDetailsModal} 
                variant="outlined"
              >
                Close
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    
      {/* Error Handling Snackbar */}
      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={handleCloseError}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert 
          onClose={handleCloseError} 
          severity="error" 
          sx={{ width: '100%' }}
        >
          {error}
        </Alert>
      </Snackbar>

    

    </>
  );
};

export default ReferralDropdownMenu;