import React, { createContext, useContext, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import dayjs from "dayjs";
import { Grid, Typography } from "@mui/material";
import { PatientpageContext } from "../../context/GlobalContext";
import Skeleton from "@mui/material/Skeleton";
import DoctorsAppointmentListItem from "../../components/DoctorsAppointementListItem";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { MobileDateTimePicker } from "@mui/x-date-pickers";
import { useEffect } from "react";
import Switch from "../../Tools/Swtich";
import CalendarIcon from "../../static/images/Combined-Shape.svg";
import RecordIcon from "../../static/images/record.svg";
import { styled } from "@mui/material/styles";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import PatientRecordIcon from "../../static/images/patient_records.svg";
import MeetIcon from "../../static/images/meet_icon.svg";
import { AppointmentNavigation } from "../../static/images/ImageManager";
import Button from "../../Tools/Button";
import RightArrow from "../../static/images/right_arrow.svg";
import { Navigation } from "../../utility/utility";
import styles from "../../scss/_nextButton.scss";
import DialogScreen from "../../Tools/DialogScreen";
import NextImage from "../../static/images/next-image.svg";
import SwipableButton from "../../components/SwipableButton";
import { ServiceCall } from "../../utility/service-call";
import { Ls, ErrorComponent } from "../../utility/utility";
import PageContentLayout from "../../components/PageContentLayout";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import TooltipComponent from "../../components/TooltipComponent";
import RxoneImage from "../../static/images/Rx_symbol-removebg-preview 1.svg";
import NotesIcon from "@mui/icons-material/Notes";
import { Img } from "react-image";
import { AppointmentIcons } from "../../static/images/ImageManager";
import { constants } from "../../utility/constants";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Modal } from "@mui/material";

const Appointment = (props) => {
  const [loader, setLoader] = useState(false);
  const doctorContext = useContext(PatientpageContext);
  const setState = doctorContext.setState;

  const [showMessageCalendar, setShowMessageCalendar] = useState(false);
  const appointment =
    doctorContext.appointment || JSON.parse(Ls.get("appointmentData"));
  const [appointmentDetails, setAppointmentDetails] = useState(
    appointment?.appointmentItem
  );

  const doctorid = appointmentDetails?.doc_id;
  sessionStorage.setItem("doctorid", doctorid);
  const profileid = appointmentDetails?.pt_profile_id;
  sessionStorage.setItem("profileid", profileid);

  const patientname = appointmentDetails?.patient_name;
  sessionStorage.setItem("patientname", patientname);

  const patientage = appointmentDetails?.patient_age;
  sessionStorage.setItem("patientage", patientage);
  const phone = appointmentDetails?.patient_phone;
  sessionStorage.setItem("phone", phone);

  const patientgen = appointmentDetails?.patient_gender;
  sessionStorage.setItem("patientgen", patientgen);

  // appointment_id
  const case_file_id = appointmentDetails?.case_file_id;
  sessionStorage.setItem("case_file_id", case_file_id);

  const [openmodal, setOpenmodal] = useState(false);

  const [selectedItem, setSelectedItem] = useState(
    appointmentDetails?.doc_name || ""
  );
  const [messageDate, setMessageDate] = useState(dayjs().add(2, "days"));
  const [appointdata, setAppointdata] = useState(
    appointmentDetails?.appointment_completed || false
  );
  const [doctordata, setDoctordata] = useState([]);
  const [showCalendar, setShowCalendar] = useState(
    appointmentDetails?.followup_allowed || false
  );
  const history = useHistory();

  const [date, setDate] = useState(() => {
    if (appointmentDetails?.followup_allowed && appointmentDetails?.followup_by) {
      const initialDate = dayjs(appointmentDetails.followup_by);
      if (initialDate.isValid()) {
        return initialDate;
      }
    }
    return dayjs().add(9, "days").hour(10).minute(10);
  });


  useEffect(() => {
    if (appointmentDetails?.followup_allowed && appointmentDetails?.followup_by) {
      const apiDate = dayjs(appointmentDetails.followup_by);
      if (apiDate.isValid()) {
        setDate(apiDate);
      }
    }
  }, [appointmentDetails]);
  


  useEffect(() => {
    if (typeof window !== "undefined") {
      window.history.pushState(null, null, window.location.herf);
      window.onpopstate = () => {
        window.history.go(1);
        doctorContext?.dialogController({
          open: true,
          content: (() => (
            <div className="appointment__screen__exit">
              Do you really wish to exit?
            </div>
          ))(),
          checkLoginPage: false,
          promptWindow: true,
          dialogAction: (() => (
            <div className="appointment__screen__exit__buttons">
              <Button
                handleClick={() => {
                  doctorContext.handleDialogClose();
                  Ls.remove("pt_key");
                  Ls.remove("pt_token");
                  history.push({
                    pathname: "/",
                  });
                }}
                className="mr-2"
                text="Exit"
                containerClass="m-auto appointment__screen__verify"
              ></Button>
              <Button
                handleClick={() => {
                  doctorContext.handleDialogClose();
                }}
                className="mr-2"
                text="CANCEL"
                containerClass="m-auto appointment__screen__verify"
              ></Button>
            </div>
          ))(),
          maxWidth: "sm",
          disableBackdropClick: true,
        });
      };
    }
    if (!appointment) {
      history.push({
        pathname: "/home",
        search: history?.location?.search,
      });
    } else {
      const AppointmentDetails = async () => {
        try {
          const AppointmentData = await ServiceCall.getv2(
            "/v2/doctor/appointment/details/",
            appointment?.appointmentItem?.appointment_id,
            {
              doc_secret: Ls.get("docToken"),
              doc_key: Ls.get("docKey"),
            }
          ).then((e) => {
            setAppointmentDetails(e.data?.appointment);
            setShowCalendar(e.data?.appointment?.followup_allowed);
            setShowMessageCalendar(e?.data?.appointment?.allow_messaging);
            setAppointdata(e.data?.appointment?.appointment_completed);
            setSelectedItem(e.data?.appointment?.doc_name || "");
          });
        } catch (err) {
          doctorContext.setState({
            ...doctorContext.state,
            msg: `${err?.response?.data?.message || err}`,
            type: "error",
          });
        }
      };
      AppointmentDetails();
    }
    return () => {
      if (typeof window !== "window") {
        setAppointmentDetails("");
        window.onpopstate = null;
      }
    };
  }, []);

  useEffect(() => {
    const fetchdoctors = async () => {
      try {
        const Docotordetails = await ServiceCall.getv2(
          "/v2/doctor/list/facility/doctors/",
          `${constants.hospitalId("hosp_id")}`,
          {
            doc_secret: Ls.get("docToken"),
            doc_key: Ls.get("docKey"),
          }
        );
        if (Docotordetails) {
          setDoctordata(Docotordetails?.data?.doctors);
        }
      } catch (err) {}
    };
    fetchdoctors();
  }, []);

  useEffect(() => {
    const switchdata = async () => {
      try {
        const response = await ServiceCall.getv2(
          "/v2/doctor/appointment/messaging/",
          appointment?.appointmentItem?.appointment_id,
          {
            doc_secret: Ls.get("docToken"),
            doc_key: Ls.get("docKey"),
          }
        );
        if (response) {
          setMessageDate(response?.data?.allow_messaging_upto);
          setShowMessageCalendar(response?.data?.allow_messaging);
        }
      } catch (err) {}
    };
    switchdata();
  }, []);

  const handleSwitchChange = async (checked) => {
    try {
      // Set the state based on the new checked value
      setAppointdata(checked);

      // Call the PUT API regardless of the checked value
      await callPutApi(checked);
    } catch (error) {
      // Handle errors
      console.error("Error occurred while handling switch change:", error);
    }
  };

  const callPutApi = async (checked) => {
    try {
      const body = {
        appointment_completed: checked,
      };
      const checkdata = await ServiceCall.putv2(
        "/v2/doctor/prescription/completed/switch/",
        appointment?.appointmentItem?.appointment_id,
        body,
        {
          "Content-Type": "application/json",
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        }
      );
    } catch (err) {}
  };

  const handleNavigation = async (call, api) => {
    try {
      const data = await call(
        api,
        appointment?.appointmentItem?.appointment_id || "",
        "",
        {
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        }
      );
      if (data) {
        console.log(data);
      }
    } catch (error) {
      doctorContext.setState({
        ...doctorContext.state,
        msg: error.response.data.message || "Internal Server Error",
        type: "error",
      });
    }
  };

  const handleClick = (name) => {
    let params = history?.location?.search?.replace(
      `&app_id=${appointment?.appointmentItem?.appointment_id}`,
      ""
    );
    switch (name) {
      case "View patient Records":
        history.push({
          pathname: "/records",
          search: params,
        });
        break;
      case "Meet Now":
        // handleNavigation(ServiceCall.putv2, "/v2/doctor/start/call/");
        history.push({
          pathname: `/join/${appointmentDetails?.room_id}`,
          search: history?.location?.search,
        });
        break;
      case "Cancel Appointment":
        handleNavigation(ServiceCall.postv2, "/v2/doctor/cancel/appointment/");
        break;
      case "Write/Edit Prescription":
        history.push({
          pathname: "/write-prescriptions",
          search: history?.location?.search,
        });
        break;
      case "Write/Edit Dental Prescription":
        history.push({
          pathname: "/dental-prescriptions",
          search: history?.location?.search,
        });
        break;
      case "Add Services":
        history.push({
          pathname: "/add-services",
          search: history?.location?.search,
        });
        break;
      case "Case Files":
        history.push({
          pathname: "/add-files",
          search: history?.location?.search,
        });
        break;
      default:
        history.push({
          pathname: "/home/0",
          search: params,
        });
    }
  };

  const handleDoctorChange = (event) => {
    const selectedDoctor = event.target.value;
    setSelectedItem(selectedDoctor);

    if (selectedDoctor && typeof selectedDoctor === "object") {
      setOpenmodal(true);
    }
  };

  const callPostAPI = async () => {
    try {
      const docId =
        typeof selectedItem === "object" ? selectedItem.doc_id : selectedItem;
      const response = await ServiceCall.postv2(
        "/v2/doctor/assign/appointment/",
        `${constants.hospitalId("hosp_id")}/${
          appointment?.appointmentItem?.appointment_id
        }`,
        { doc_id: docId },
        {
          "Content-Type": "application/json",
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        }
      );
      if (response) {
        history.push({
          pathname: "/home",
        });
        doctorContext?.getAppointment();
        setState({
          msg: response?.data.message || "Doctor assigned successfully",
          type: "success",
          timer: null,
          vertical: "top",
          horizontal: "center",
        });
      }
      setOpenmodal(false);
    } catch (err) {
      setState({
        msg: err?.response?.data?.message || "Error assigning doctor",
        type: "error",
        timer: null,
        vertical: "top",
        horizontal: "center",
      });
      setOpenmodal(false);
    }
  };

  const handleNextPatient = async () => {
    setLoader(true);
    try {
      const data = await ServiceCall.postv2(
        "/v2/doctor/next/patient/",
        appointment?.appointmentItem?.appointment_id || "",
        "",
        {
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        }
      );
      if (data) {
        setLoader(false);
        history.push({
          pathname: "/home/0",
          search: history?.location?.search,
        });
      }
    } catch (err) {
      setLoader(false);
      doctorContext.setState({
        ...doctorContext.state,
        msg: err.response.data.message,
        type: "error",
      });
    }
  };
  const AppointmentIcon = ({ type }) => {
    if (type?.includes("Online")) {
      return <Img src={AppointmentIcons.online} />;
    } else if (type?.includes("In-Person")) {
      return <Img src={AppointmentIcons.inperson} />;
    }
    return <Img src={AppointmentIcons.walkin} />;
  };
  const DoctorsAppointmentList = ({
    appointmentDate,
    classes,
    appointmentDetails,
  }) => {
    const FollowUpComponent = ({
      appointmentDate,
      appointmentDetails,
      record = false,
    }) => {
      const CalendarMonth = ({
        label,
        formate,
        handleChange,
        date,
        includeTime = false,
        showCalendar,
      }) => {
        const [tempDate, setTempDate] = useState(date);
    
        // Keep tempDate in sync with parent date
        useEffect(() => {
          setTempDate(date);
        }, [date]);
    
        return (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div className="newappointment__container__calender__datePicker appointment__date__calendar__date">
            {includeTime ? (
                <MobileDateTimePicker
                  label={label}
                  value={tempDate}
                  onChange={(newValue) => {
// Only update the temporary local state
                    setTempDate(newValue);
                  }}
                  onAccept={(newValue) => {
                    // Call API only when OK button is clicked
                    if (newValue && newValue.isValid()) {
                      handleChange(newValue, showCalendar);
                    }
                  }}
                  onClose={() => {
                      // Reset temp date if dialog is closed without accepting
                    setTempDate(date);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                  format="YYYY-MM-DD HH:mm"
                  minutesStep={5}
                  views={["year", "month", "day", "hours", "minutes"]}
                />
              ) : (
                <MobileDatePicker
                  label={label}
                  inputFormat={formate}
                  value={date}
                  onChange={(value) => handleChange(value, showCalendar)}
                  renderInput={(params) => <TextField {...params} />}
                />
              )}
              <img src={CalendarIcon} alt="calendar" />
            </div>
          </LocalizationProvider>
        );
      };

      const handleFollowUP = (checked) => {
        setShowCalendar(checked);
    
        if (checked) {
          // If switching on and we have a valid API date, use it
          if (appointmentDetails?.followup_by) {
            const apiDate = dayjs(appointmentDetails.followup_by);
            if (apiDate.isValid()) {
              setDate(apiDate);
              handleDateChange(apiDate, checked);
              return;
            }
          }
          // Otherwise use default date
          const defaultDate = dayjs().add(9, "days").hour(10).minute(10);
          setDate(defaultDate);
          handleDateChange(defaultDate, checked);
        } else {
          // When turning off, use default date
          const defaultDate = dayjs().add(9, "days").hour(10).minute(10);
          setDate(defaultDate);
          handleDateChange(defaultDate, checked);
        }
      };

      const handleMessageSwitch = (checked) => {
        setShowMessageCalendar(checked);
        if (checked) {
          handleMessageDateChange(dayjs().add(2, "days"), checked);
        }
      };

      const handleMessageDateChange = async (value, checked) => {
        try {
          const messageFollowUp = await ServiceCall.putv2(
            "/v2/doctor/appointment/messaging/",
            appointmentDate?.appointment_id,
            {
              allow_messaging_upto: value.format("YYYY-MM-DD"),
              allow_messaging: checked,
            },
            {
              "Content-Type": "application/json",
              doc_secret: Ls.get("docToken"),
              doc_key: Ls.get("docKey"),
            }
          );

          if (messageFollowUp) {
            setMessageDate(messageFollowUp?.data?.allow_messaging_upto);
            setShowMessageCalendar(messageFollowUp?.data?.allow_messaging);
          }
        } catch (err) {
          doctorContext.setState({
            ...doctorContext.state,
            msg: `${err?.response?.data?.message || err}`,
            type: "error",
          });
        }
      };

      const handleDateChange = async (value, checked) => {
        if (!value || !value.isValid()) {
          console.error("Invalid date value:", value);
          return;
        }
    
        try {
          const followUp = await ServiceCall.putv2(
            "/v2/doctor/appointment/followup/",
            appointmentDate?.appointment_id,
            {
              followup_by: value.format("YYYY-MM-DD HH:mm:ss"),
              followup_allowed: checked,
            },
            {
              "Content-Type": "application/json",
              doc_secret: Ls.get("docToken"),
              doc_key: Ls.get("docKey"),
            }
          );
    
          if (followUp?.data) {
            if (followUp.data.followup_allowed) {
              // If followup is allowed, use the API date
              setDate(dayjs(followUp.data.followup_by));
            } else {
              // If followup is not allowed, use default date
              setDate(dayjs().add(9, "days").hour(10).minute(10));
            }
            setShowCalendar(followUp.data.followup_allowed);
          }
        } catch (err) {
          doctorContext.setState({
            ...doctorContext.state,
            msg: `${err?.response?.data?.message || err}`,
            type: "error",
          });
        }
      };

      return (
        <div>
          <div className="appointment__date__card">
            {/* <div className="appointment__date__text">
              <span>
                {dayjs(appointmentDate?.appointment_date_formatted).format(
                  "ddd,"
                )}
              </span>
              {dayjs(appointmentDate?.appointment_date_formatted).format(
                "D MMM YYYY"
              )}
            </div> */}
            {record ? (
              <div className="appointment__date__record">
                <span>Records</span>
                <img src={RecordIcon} />
              </div>
            ) : (
              <div></div>
            )}
          </div>
          <div
            className="case-info"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
              padding: "1rem",
              backgroundColor: "#f5f5f5",
              borderRadius: "8px",
              margin: "0rem 0",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "72%",
                margin: "auto",
              }}
            >
               <div className="appointment__date__calendar">
      <Switch
        label="Allow Follow up? (No-Charge)"
        labelInput="remind_patient"
        checked={showCalendar}
        ContainerClass="prescriptionpage__medication__reminder__switch"
        handleChange={(e) => handleFollowUP(e.target.checked)}
      />
      {showCalendar ? (
        <CalendarMonth
          label="Follow-up By"
          formate="YYYY-MM-DD HH:mm"
          date={date}
          handleChange={handleDateChange}
          includeTime={true}
          showCalendar={showCalendar}
        />
      ) : null}
    </div>

              <div className="appointment__date__calendar">
                <Switch
                  label="Allow Messaging?"
                  labelInput="allow_message"
                  checked={showMessageCalendar}
                  ContainerClass="prescriptionpage__medication__reminder__switch"
                  handleChange={(e) => handleMessageSwitch(e.target.checked)}
                />
                {showMessageCalendar ? (
                  <CalendarMonth
                    label="Allow Messaging Upto"
                    formate={"YYYY-MM-DD"}
                    date={messageDate || dayjs().add(2, "days")}
                    handleChange={(value) =>
                      handleMessageDateChange(value, showMessageCalendar)
                    }
                  />
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      );
    };

    function renderAppointmentList(item) {
      return (
        <DoctorsAppointmentListItem
          index={0}
          appointmentItem={appointmentDetails}
          classes={classes}
          handleOnClick={() => {}}
        />
      );
    }

    return (
      <>
        <div className="appointment__date__card">
          <div className="appointment__date__text">
            <span>
              {dayjs(appointmentDate?.appointment_date_formatted).format(
                "ddd,"
              )}
            </span>
            {dayjs(appointmentDate?.appointment_date_formatted).format(
              "D MMM YYYY"
            )}
            {appointmentDate?.appointment_time_formatted && (
              <span
                className="appointment__time"
                style={{ marginLeft: ".3rem" }}
              >
                {" "}
                {" - "}
                {appointmentDate.appointment_time_formatted}
              </span>
            )}
          </div>
        </div>
        <div
          className="appointment__container"
          style={{ background: "#e8f3f1" }}
        >
          {appointmentDetails && (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ textAlign: "center" }}>Name</TableCell>
                  <TableCell style={{ textAlign: "center" }}>Phone</TableCell>
                  <TableCell style={{ textAlign: "center" }}>Age</TableCell>
                  <TableCell style={{ textAlign: "center" }}>Gender</TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    Prescription Status
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>Type</TableCell>
                  <TableCell style={{ textAlign: "center" }}>Time</TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    Appointment Status
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell style={{ textAlign: "center" }}>
                    {appointmentDetails?.patient_name}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {appointmentDetails?.patient_phone || "NA"}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {appointmentDetails?.patient_age}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {appointmentDetails?.patient_gender || "NA"}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    <Img src={RxoneImage} />{" "}
                    {appointmentDetails?.prescription_status}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    <AppointmentIcon
                      type={
                        appointmentDetails?.app_type ||
                        appointmentDetails?.appointment_type
                      }
                    />{" "}
                    {(
                      appointmentDetails?.app_type ||
                      appointmentDetails?.appointment_type
                    )
                      ?.replace("Scheduled ", "")
                      ?.replace("(", "")
                      ?.replace(")", "")}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {appointmentDetails?.appointment_start_time}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {appointmentDetails?.app_status}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          )}
        </div>

        <div className="appointment__caseid__container">
          <div className="appointment__caseid__content">
            <div className="case-id">
              <span>Case ID:</span>
              <p>{appointmentDetails?.case_id}</p>
              <div
                className="appointment__container__lastsection__container"
                style={{ marginBottom: "0", marginLeft: "2rem" }}
              >
                {appointmentDetails?.reported_problem ? (
                  <TooltipComponent
                    icon={
                      <NotesIcon
                        sx={{
                          width: "100%",
                          color: "#199a8e",
                          fontSize: "2rem",
                        }}
                      />
                    }
                    title={appointmentDetails?.reported_problem}
                  />
                ) : (
                  <NotesIcon
                    sx={{
                      width: "100%",
                      color: "#dddddd",
                      fontSize: "2rem",
                    }}
                    disabled
                  />
                )}{" "}
              </div>
            </div>
            <div className="doctor-dropdown">
              <span>Assign To:</span>
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <Select
                    labelId="Assigned-doc"
                    id="Assigned-doc"
                    value={selectedItem}
                    onChange={handleDoctorChange}
                    variant="outlined"
                  >
                    {appointmentDetails?.doc_name && (
                      <MenuItem value={appointmentDetails.doc_name}>
                        {appointmentDetails.doc_name}
                      </MenuItem>
                    )}
                    {doctordata.map((item, index) => (
                      <MenuItem key={index} value={item}>
                        {item.doctor_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </div>
          </div>
        </div>

        <Grid item xs={12}>
          <FollowUpComponent
            appointmentDate={appointmentDate}
            appointmentDetails={appointmentDetails}
          />
        </Grid>
      </>
    );
  };

  const rupee = "\u20B9";

  return (
    <>
      <PageContentLayout>
        <div>
          <div className="appointmentpage">
            <DialogScreen />
            <Navigation
              text={`Appointment${
                appointmentDetails?.is_followup_appointment
                  ? " (Follow-Up)"
                  : ""
              }`}
              navClass="appointmentpage__navigation"
              handleClick={handleClick}
            />
            {appointment ? (
              <DoctorsAppointmentList
                appointmentDate={appointment?.appointmentItem}
                appointmentDetails={appointmentDetails}
                classes={appointment?.classes}
              />
            ) : (
              <></>
            )}

            <div className="">
              <div className="appointmentpage__navigation__list">
                {Array.isArray(AppointmentNavigation) &&
                  AppointmentNavigation?.map((list) =>
                    appointmentDetails[list?.show] ||
                    list?.show === "pateint Records" ? (
                      <Navigation
                        text={
                          list?.text === "Add Services" &&
                          appointmentDetails?.order_balance_due !== undefined &&
                          parseFloat(appointmentDetails.order_balance_due) >
                            0 ? (
                            <>
                              {list.text}{" "}
                              <span style={{ color: "red" }}>
                                (Balance Due:{rupee}
                                {parseFloat(
                                  appointmentDetails.order_balance_due
                                ).toFixed(2)}
                                )
                              </span>
                            </>
                          ) : (
                            list?.text || ""
                          )
                        }
                        image={list?.image || ""}
                        close={true}
                        navClass="appointmentpage__navigation__list__button"
                        handleClick={() => handleClick(list.text)}
                      />
                    ) : (
                      <>
                        {!appointmentDetails && (
                          <Typography
                            component="div"
                            variant="h3"
                            style={{ width: "90%", margin: "auto" }}
                          >
                            {<Skeleton />}
                          </Typography>
                        )}
                      </>
                    )
                  )}
              </div>

              <div
                className="appointment__date__switch"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: ".5rem",
                }}
              >
                <Switch
                  label="Mark Appointment Completed"
                  labelInput="remind_patient"
                  checked={appointdata}
                  ContainerClass="prescriptionpage__medication__reminder__switch"
                  // handleChange={handleSwitchChange}
                  handleChange={(e) => handleSwitchChange(e.target.checked)}
                />
                <Button
                  // image={NextImage}
                  handleClick={handleNextPatient}
                  text="Next Patient"
                  // text_unlocked={loader ? "loading...." : "unlocked"}
                />
              </div>
            </div>
          </div>
        </div>
        <Modal
          open={openmodal}
          onClose={() => setOpenmodal(false)}
          aria-labelledby="confirmation-modal-title"
          aria-describedby="confirmation-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              borderRadius: 2,
            }}
          >
            <Typography
              id="confirmation-modal-title"
              variant="h6"
              component="h2"
              gutterBottom
              style={{ textAlign: "center" }}
            >
              Confirm the Assignment!
            </Typography>
            <Typography
              id="confirmation-modal-description"
              sx={{ mt: 2 }}
              style={{ textAlign: "center" }}
            >
              Please confirm if you wish to assign the case to{" "}
              <span style={{ fontWeight: "bold" }}>
                {typeof selectedItem === "object"
                  ? selectedItem.doctor_name
                  : selectedItem}
              </span>
            </Typography>
            <Box
              sx={{ mt: 3, display: "flex", justifyContent: "space-around" }}
            >
              <button
                style={{
                  padding: "10px 15px",
                  fontSize: "12px",
                  background: "#199a8e",
                  color: "#fff",
                  lineHeight: "15px",
                  width: "40%",
                  borderRadius: "32px",
                  display: "flex",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
                onClick={callPostAPI}
              >
                Proceed
              </button>
              <button
                style={{
                  padding: "10px 15px",
                  fontSize: "12px",
                  background: "grey",
                  color: "#fff",
                  lineHeight: "15px",
                  width: "40%",
                  borderRadius: "32px",
                  display: "flex",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
                onClick={() => setOpenmodal(false)}
              >
                Cancel
              </button>
            </Box>
          </Box>
        </Modal>
      </PageContentLayout>
    </>
  );
};

export default Appointment;
