import React, { useState, useContext, useEffect, useRef } from "react";
import {
  TextField,
  Button,
  Container,
  makeStyles,
  FormControl,
  InputLabel,
  InputAdornment,
  Input,
  MenuItem,
  FormHelperText,
  Select,
  Grid,
} from "@material-ui/core";
import Header from "../../components/Header";
import Registrationpic from "../../static/images/Regitrationpic-removebg-preview.png";
import { ServiceCall } from "../../utility/service-call";
import { Ls } from "../../utility/utility";
import { PatientpageContext } from "../../context/GlobalContext";
import Doctorotp from "./Doctorotp";
import { useHistory, useLocation } from "react-router-dom";
import { countriesList } from "../../utility/utility";
import { forEach } from "lodash";
import CircularProgress from "@material-ui/core/CircularProgress";
import doctorlogo from "../../static/images/Doctor_Portal_144.png";
import headerlogo from "../../static/images/header-logo.png";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(4),
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
    "& .MuiFormControl-root": {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(2, 0),
    backgroundColor: "#199a8e",
    "&:hover": {
      // Remove hover effect
      backgroundColor: "#199a8e",
    },
  },
  salutationFormControl: {
    width: "27%", // Adjust the width of the salutation select
  },
  gender: {
    width: "30%",
  },
  nameContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  nameField: {
    width: "50%", // Width of first name and last name fields
  },
  select: {
    border: "none", // Remove border
    width: "50px", // Adjust the width as needed
  },
}));

const Doctorregis = () => {
  const classes = useStyles();
  const doctorContext = useContext(PatientpageContext);
  const setState = doctorContext.setState;

  const history = useHistory();
  const location = useLocation();

  const [formData, setFormData] = useState({
    title: "Dr.",
    first_name: "",
    wa_otp: "false",
    last_name: "",
    gender: "male",
    phone: "",
  });

  const [emaildata, setEmaildata] = useState({
    email: "",
  });
  const [apiSuccess, setApiSuccess] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [loading, setLoading] = useState(false); // State for managing loading state

  const [phonedata, setPhonedata] = useState("");
  const [maildata, setMaildata] = useState("");
  const [phoneCode, setPhoneCode] = useState("+91"); // State to hold the selected country code
  const [showOTP, setShowOTP] = useState(false);
  const [otpLoader, getOtpLoader] = useState(false);
  const [otpContent, setOtpContent] = useState("");
  const [apiError, setApiError] = useState("");
  const [showOtp, setShowOtp] = useState(false);
  const [disableSendOtp, setDisableOtp] = useState(true);
  const timer = useRef(null);
  const otpField = useRef(null);


  useEffect(() => {
    if (typeof window !== "undefined") {
      window.history.pushState(null, null, window.location.herf);
      window.onpopstate = () => {
        window.history.go(1);
        
      };
    }
    return () => {
      if (typeof window !== "window") {
        window.onpopstate = null;
      }
    };
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      // Cancel the event
      event.preventDefault();
      // Chrome requires returnValue to be set
      event.returnValue = "";
    };

    // Add the event listener when the component mounts
    window.addEventListener("beforeunload", handleBeforeUnload);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const otpButtonTimer = function () {
    timer.current = setTimeout(() => {
      setDisableOtp(false);
    }, 60000);
  };
  const isValidPhoneNumber = (phoneNumber) => {
    // Remove any non-numeric characters from the phone number
    const numericPhoneNumber = phoneNumber.replace(/\D/g, "");

    // Check if the phone number has exactly 10 digits and contains only numeric characters
    return numericPhoneNumber.length === 10 && /^\d+$/.test(numericPhoneNumber);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormData({ ...formData, [name]: value });
    if (name === "phone") {
      if (!isValidPhoneNumber(value)) {
        // Define isValidPhoneNumber according to your validation logic
        setPhoneError(true); // Set phone number error state
      } else {
        setPhoneError(false); // Reset phone number error state
      }
    }
  };
  const handleChan = (event) => {
    setPhoneCode(event.target.value);
  };

  const handleChange2 = (event) => {
    const { name, value } = event.target;
    setEmaildata({ ...emaildata, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const body = {
        title: formData?.title,
        first_name: formData?.first_name,
        wa_otp: formData?.wa_otp,
        last_name: formData?.last_name,
        gender: formData?.gender,
        phone: phoneCode + formData?.phone,
      };
      if (formData && emaildata) {
        // Concatenate phone code and phone number
        // Call the first API to submit phone number details
        const phoneResponse = await ServiceCall.put(
          "/v2/generate/doctor/registration/mobile/otp/",
          body
        );
        if (phoneResponse) {
          setPhonedata(phoneResponse.data);
        }

        // Call the second API to submit email details
        const emailResponse = await ServiceCall.put(
          "/v2/generate/doctor/registration/email/otp/",
          emaildata
        );
        if (emailResponse) {
          setMaildata(emailResponse.data);
        }

        if (phoneResponse && emailResponse) {
          setLoading(false); // Set loading to false after receiving response
          setApiSuccess(true);
          getOtpLoader(false);
          setShowOTP(true);
          setState({
            msg: phoneResponse?.data.message || "",
            type: "success",
            timer: null,
            vertical: "top",
            horizontal: "center",
          });
          setOtpContent(phoneResponse?.data.message);
        }
      }
    } catch (err) {
      // Handle errors
      getOtpLoader(false);
      if (err.response) {
        setLoading(false); // Set loading to false after receiving response
        setApiError(err.response.data.message);
        setState({
          msg: err?.response?.data?.message || "",
          type: "error",
          timer: null,
          vertical: "top",
          horizontal: "center",
        });
      } else if (err.request) {
        setApiError("Failed request " + err);
        setLoading(false); // Set loading to false after receiving response
      } else {
        setApiError("Failed in general " + err);
        setLoading(false); // Set loading to false after receiving response
      }
    }
  };
  useEffect(() => {
    if (showOtp) {
      otpButtonTimer();
      otpField.current.focus();
    }
  }, [showOtp]);


  const handlePhoneCodeChange = (event) => {
    setPhoneCode(event.target.value);
  };

  return (
    <>
      {apiSuccess ? (
        <Doctorotp forData={formData} emaildata={emaildata} />
      ) : (
        <div className="loginCo bodyBg">

                  <Container component="main" maxWidth="xs">

          <div
            style={{
              textAlign: "center",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <p
              style={{ fontSize: "18px", fontWeight: "500", color: "#92b6d3" }}
            >
              Welcome To
            </p>
            <img src={headerlogo} style={{ maxWidth: "78px", margin: "5px" }} />
          </div>

          <div className="patienthead" style={{maxWidth: "xs"}} >
            <h1
              style={{
                fontSize: "18px",
                fontWeight: "500",
                color: "rgb(0 99 120)",
                textAlign: "center",
              }}
            >
              Patients are looking for practitioners like you
            </h1>
            <p style={{ color: "#8b9fb3", textAlign: "center" }}>
              Millions of patients are looking for the right doctor on RxOne.
              Start your digital journey with RxOne Profile.
            </p>
          </div>
          </Container>
          <Container component="main" maxWidth="xs">
            <form className={classes.root} onSubmit={handleSubmit}>
              <img
                src={Registrationpic}
                alt="Registration"
                style={{ marginBottom: "-7px", borderRadius: "5px" }}
              />
              <h3 style={{ fontWeight: "600", color: "#8b9fb3" }}>
                Verify Contact Details
              </h3>
              <p style={{ color: "#8b9fb3", textAlign: "center" }}>
                We will use the phone no. and email to verify your contact
                details
              </p>
              <div className={classes.nameContainer}>
                <FormControl
                  variant="outlined"
                  className={classes.salutationFormControl}
                  required
                >
                  <InputLabel id="salutation-label">Title</InputLabel>
                  <Select
                    labelId="salutation-label"
                    id="salutation"
                    name="title"
                    value={formData?.title}
                    onChange={handleChange}
                    label="title"
                  >
                    <MenuItem value="Dr.">Dr.</MenuItem>
                    <MenuItem value="Mr.">Mr.</MenuItem>
                    <MenuItem value="Mrs.">Mrs.</MenuItem>
                    <MenuItem value="Ms.">Ms.</MenuItem>
                    <MenuItem value="NA">NA</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  id="firstName"
                  name="first_name"
                  label="First Name"
                  variant="outlined"
                  value={formData?.first_name}
                  onChange={handleChange}
                  required
                  autoFocus
                  className={classes.nameField}
                />
                <TextField
                  id="lastName"
                  name="last_name"
                  label="Last Name"
                  variant="outlined"
                  value={formData?.last_name}
                  onChange={handleChange}
                  required
                  className={classes.nameField}
                />
              </div>
              <FormControl
                variant="outlined"
                className={classes.gender}
                fullWidth
                style={{ width: "96%" }}
                required
              >
                <InputLabel id="gender">Gender</InputLabel>
                <Select
                  labelId="gender"
                  id="gender"
                  name="gender"
                  label="Gender"
                  value={formData?.gender}
                  onChange={handleChange}
                >
                  <MenuItem value="male">Male</MenuItem>
                  <MenuItem value="female">Female</MenuItem>
                  <MenuItem value="other">Other</MenuItem>
                </Select>
              </FormControl>

              <FormControl variant="outlined" style={{ width: "95%" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={phoneCode}
                    onClick={handlePhoneCodeChange}
                    className="w-23"
                    classes={{ select: classes.select }}
                  >
                    {countriesList
    .slice() // create a copy to avoid mutating original array
    .sort((a, b) => a.code.localeCompare(b.code)) // sort alphabetically
    .map((country, index) => (     
    <MenuItem       
      key={`${country.code}-${index}`}       
      value={country.code}       
      style={{marginRight: "5rem  !important"}}     
    >       
      {country.code}     
    </MenuItem>   
  ))} 
                  </Select>

                  <InputLabel
                    htmlFor="phoneNumber"
                    style={{ marginLeft: "101px" }}
                  >
                    Phone Number*
                  </InputLabel>
                  <Input
                    id="phoneNumber"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    label="Phone Number"
                    required
                    error={phoneError}
                    style={{ flex: 1, marginLeft: 6, width: "100px" }} // Adjust the margin and flex properties
                  />
                </div>
                {phoneError && (
                  <FormHelperText error>
                    Please enter a valid phone number.
                  </FormHelperText>
                )}
              </FormControl>

              <TextField
                id="email"
                name="email"
                label="Email"
                variant="outlined"
                value={emaildata?.email}
                onChange={handleChange2}
                type="email"
                required
                fullWidth
                style={{ width: "95%" }}
              />
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
                disabled={loading} // Disable button when loading is true
              >
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Next"
                )}
              </Button>
            </form>
          </Container>
        </div>
      )}
    </>
  );
};

export default Doctorregis;
